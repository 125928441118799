import React, {useEffect, useState} from "react";
import {Menu, MenuItem, ProSidebar} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {Box, Typography, useTheme} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {tokens} from "../theme";
import {connect} from 'react-redux';

import DashboardIcon from '@mui/icons-material/Dashboard';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import PublicIcon from '@mui/icons-material/Public';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LinkIcon from '@mui/icons-material/Link';
import FacebookIcon from '@mui/icons-material/Facebook';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import FaceIcon from '@mui/icons-material/Face';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import KeyIcon from '@mui/icons-material/Key';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import {Calender} from "./Calender";
import UserSelect from "./UserSelect";

const Item = ({title, to, icon, selected, setSelected}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to}/>
    </MenuItem>
  );
};

const Sidebar = ({isAuthenticated, user}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();
  const [selected, setSelected] = useState("");

  useEffect(() => {
    const path = location.pathname;

    if (path.includes("/dashboard/admin")) {
      setSelected(user.is_team_lead ? "Dashboard Team" : "Dashboard Admin");
    } else if (path.includes("/dashboard")) {
      setSelected("Dashboard");
    } else if (path.includes("/keywords")) {
      setSelected("Keywords Search");
    } else if (path.includes("/ads/statistic")) {
      setSelected("Statistic Ads");
    } else if (path.includes("/ads/domains")) {
      setSelected("Domains Ads");
    } else if (path.includes("/tonic/statistic")) {
      setSelected("Statistic Tonic");
    } else if (path.includes("/tonic/campaigns")) {
      setSelected("Campaigns Tonic");
    } else if (path.includes("/tiktok/advertiser")) {
      setSelected("TikTok");
    } else if (path.includes("/facebook/adaccounts")) {
      setSelected("Facebook");
    } else if (path.includes("/cross/statistic")) {
      setSelected("Statistic Cross");
    } else if (path.includes("/cross/campaigns")) {
      setSelected("Campaigns Cross");
    } else if (path.includes("/sedo/statistic")) {
      setSelected("Statistic Sedo");
    } else if (path.includes("/sedo/domains")) {
      setSelected("Domains Sedo");
    } else if (path.includes("/rsoc/codefuel/statistic")) {
      setSelected("CodeFuel RSOC");
    } else if (path.includes("/rsoc2/statistic")) {
      setSelected("RSOC 2");
    } else if (path.includes("/codefuel/statistic/ADS2")) {
      setSelected("Statistic Ads 2");
    } else if (path.includes("/codefuel/campaigns/ADS2")) {
      setSelected("Campaigns Ads 2");
    } else if (path.includes("/codefuel/statistic/TONIC2")) {
      setSelected("Statistic Tonic 2");
    } else if (path.includes("/codefuel/campaigns/TONIC2")) {
      setSelected("Campaigns Tonic 2");
    } else if (path.includes("/redtrack")) {
      setSelected("RedTrack");
    } else if (path.includes("/clickflare")) {
      setSelected("ClickFlare");
    } else if (path.includes("/taboola/advertiser")) {
      setSelected("Taboola");
    } else if (path.includes("/outbrain/marketer")) {
      setSelected("OutBrain");
    } else if (path.includes("/zemanta/advertiser")) {
      setSelected("Zemanta");
    } else if (path.includes("/system1/statistic")) {
      setSelected("Statistic System1");
    } else if (path.includes("/system1/domains")) {
      setSelected("Domains System1");
    }

  }, [location]);

  if (!isAuthenticated) {
    return <div></div>
  }


  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
          height: "25px !important",
        },
        "& .pro-inner-item": {
          padding: "0px 35px 0px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            icon={isCollapsed ? <MenuOutlinedIcon/> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                ml="15px"
              >
                <Link to={`/`} style={{textDecoration: 'none', color: 'inherit'}}>
                  <img
                    alt="profile-user"
                    width="100%"
                    src={process.env.REACT_APP_API_URL + '/static/logo-white.png'}
                    style={{cursor: "pointer"}}
                  />
                </Link>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box>
              <Box textAlign="center">
                <hr color={colors.grey[100]} style={{margin: '0 0 14px 0'}}/>
                <Calender/>
                <Box sx={{padding: '0 0'}}>
                  {user.is_team_lead && !user.is_superuser ? (
                    <UserSelect currentUser={user}/>
                  ) : (
                    <Box sx={{
                      padding: '14px 12px 14px 12px',
                      color: colors.grey[100],
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}>
                      {user.user_id ? `${user.user_id} - ${user.email}` : user.email}
                    </Box>
                  )}
                </Box>
                <hr color={colors.grey[100]} style={{margin: '0 0 20px 0'}}/>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}
               sx={{
                 overflowY: 'auto',
                 height: 'calc(100vh - 270px)',
                 scrollbarWidth: 'thin',
                 scrollbarColor: 'rgba(100, 100, 100, 0.3) rgba(0, 0, 0, 0)',
               }}
          >

            {user.is_team_lead === true || user.is_superuser === true ?
              (<Item
                title={user.is_team_lead ? "Dashboard Team" : "Dashboard Admin"}
                to={`/dashboard/admin`}
                icon={<DashboardIcon/>}
                selected={selected}
                setSelected={setSelected}
              />) :
              null
            }

            <Item
              title="Dashboard"
              to={`/dashboard/${user.id}`}
              icon={<DashboardIcon/>}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Keywords Search"
              to="/keywords"
              icon={<KeyIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            {user.is_superuser && (
              <Item
                title="Domains Register"
                to="/domains"
                icon={<PublicIcon/>}
                selected={selected}
                setSelected={setSelected}
              />
            )}

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{m: "15px 0 5px 20px"}}
            >
              Traffic Channels
            </Typography>
            <Item
              title="TikTok"
              to="/tiktok/advertiser"
              icon={<MusicNoteIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Facebook"
              to="/facebook/adaccounts"
              icon={<FacebookIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Taboola"
              to="/taboola/advertiser"
              icon={<AllInclusiveIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="OutBrain"
              to="/outbrain/marketer"
              icon={<FaceIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Zemanta"
              to="/zemanta/advertiser"
              icon={<OfflineBoltIcon/>}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{m: "15px 0 5px 20px"}}
            >
              Trackers
            </Typography>
            <Item
              title="ClickFlare"
              to="/clickflare"
              icon={<LinkIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="RedTrack"
              to="/redtrack"
              icon={<LinkIcon/>}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{m: "15px 0 5px 20px"}}
            >
              Ads
            </Typography>
            <Item
              title="Statistic Ads"
              to="/ads/statistic"
              icon={<LeaderboardIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Domains Ads"
              to="/ads/domains"
              icon={<ChecklistRtlIcon/>}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{m: "15px 0 5px 20px"}}
            >
              Tonic
            </Typography>
            <Item
              title="Statistic Tonic"
              to="/tonic/statistic"
              icon={<LeaderboardIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Campaigns Tonic"
              to="/tonic/campaigns"
              icon={<ChecklistRtlIcon/>}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{m: "15px 0 5px 20px"}}
            >
              Crossroads
            </Typography>
            <Item
              title="Statistic Cross"
              to="/cross/statistic"
              icon={<LeaderboardIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Campaigns Cross"
              to="/cross/campaigns"
              icon={<ChecklistRtlIcon/>}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{m: "15px 0 5px 20px"}}
            >
              Sedo
            </Typography>
            <Item
              title="Statistic Sedo"
              to="/sedo/statistic"
              icon={<LeaderboardIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Domains Sedo"
              to="/sedo/domains"
              icon={<ChecklistRtlIcon/>}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{m: "15px 0 5px 20px"}}
            >
              Ads 2
            </Typography>
            <Item
              title="Statistic Ads 2"
              to="/codefuel/statistic/ADS2"
              icon={<LeaderboardIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Campaigns Ads 2"
              to="/codefuel/campaigns/ADS2"
              icon={<ChecklistRtlIcon/>}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{m: "15px 0 5px 20px"}}
            >
              Tonic 2
            </Typography>
            <Item
              title="Statistic Tonic 2"
              to="/codefuel/statistic/TONIC2"
              icon={<LeaderboardIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Campaigns Tonic 2"
              to="/codefuel/campaigns/TONIC2"
              icon={<ChecklistRtlIcon/>}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{m: "15px 0 5px 20px"}}
            >
              System1
            </Typography>
            <Item
              title="Statistic System1"
              to="/system1/statistic"
              icon={<LeaderboardIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Domains System1"
              to="/system1/domains"
              icon={<ChecklistRtlIcon/>}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{m: "15px 0 5px 20px"}}
            >
              Yahoo
            </Typography>
            <Item
              title="Statistic Yahoo"
              to="/codefuel/statistic/YAHOO"
              icon={<LeaderboardIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Channels Yahoo"
              to="/codefuel/campaigns/YAHOO"
              icon={<ChecklistRtlIcon/>}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{m: "15px 0 5px 20px"}}
            >
              RSOC
            </Typography>
            <Item
              title="CodeFuel RSOC"
              to="/rsoc/codefuel/statistic"
              icon={<LeaderboardIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="RSOC 2"
              to="/rsoc2/statistic"
              icon={<LeaderboardIcon/>}
              selected={selected}
              setSelected={setSelected}
            />

          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});


export default connect(mapStateToProps)(Sidebar);
