import {useState} from "react";
import axios from "axios";
import {Box, Button, IconButton, Modal, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from '@mui/icons-material/Close';

import {toast} from "react-toastify";
import Header from "../../components/Header";

import {formatErrorMessage, getModalCommonStyle, requestConfig} from "../utils";

const ModalNewCampaign = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const [countries, setCountries] = useState([])
  const [offers, setOffers] = useState([])
  const [country, setCountry] = useState('')
  const [offer, setOffer] = useState({})
  const [name, setName] = useState('')
  const [account, setAccount] = useState('')

  const accounts = [
    {id: 'tonic_tt', value: 'TT'},
    {id: 'tonic_fb1', value: 'FB1'},
    {id: 'tonic_native', value: 'NATIVE'},
  ];

  const styleModal = {
    ...getModalCommonStyle(),
    width: 420,
  };

  const get_offers_and_countries = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/tonic/countries/`, requestConfig)
      .then(function (response) {
        setCountries(response.data)
      })
      .catch(function (error) {
        console.log(error);
      })

    axios.get(`${process.env.REACT_APP_API_URL}/api/tonic/offers/`, requestConfig)
      .then(function (response) {
        setOffers(response.data)
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  const openModal = async () => {
    await get_offers_and_countries()
    setOpenCreate(true);
  }

  const closeModal = () => {
    setOpenCreate(false)
  }

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  const onSubmit = () => {
    const data = {
      account: account,
      campaign_name: name,
      country: country,
      offer: offer,
    }

    axios.post(`${process.env.REACT_APP_API_URL}/api/tonic/campaigns/`, data, requestConfig)
      .then(function (response) {
        if (response.status === 201) {
          toast.success('Success! Campaign will be created in 5-20 minutes', {
            position: "top-center",
            autoClose: 3000
          });
          setOpenCreate(false);
        } else {
          toast.error(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(formatErrorMessage(error));
      })
  };

  return (
    <Box>
      <Box m="0 0 0 20px">
        <Button variant="contained" onClick={openModal}>Create new Campaign</Button>
      </Box>
      <Modal
        open={openCreate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static"
        onKeyDown={handleKeyDown}
      >
        <Box sx={styleModal}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title="Create campaign" subtitle="create new campaign in Tonic"/>

            <IconButton onClick={closeModal} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>
          </Box>

          <Autocomplete
            sx={{width: 350, marginTop: 3}}
            disableClearable
            options={accounts}
            getOptionLabel={(option) => option.value}
            onChange={(event, values) => setAccount(values.id)}
            renderInput={(params) => <TextField {...params} label="Choose an account"/>}
            value={accounts.find(item => item === account.id)}
          />

          <TextField id="outlined-basic" label="Name of campaign" variant="outlined" sx={{width: 350, marginTop: 3}}
                     onChange={(event) => setName(event.target.value)}/>

          <Autocomplete
            id="country-select-demo"
            sx={{width: 350, marginTop: 3}}
            options={countries}
            autoHighlight
            getOptionLabel={(option) => option.country_name}
            name='country'
            onChange={(event, values) => setCountry(values.country_code)}

            renderOption={(props, option) => (
              <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.country_code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.country_code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.country_name} ({option.country_code})
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a country"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />

          <Autocomplete
            id="combo-box-demo"
            options={offers}
            getOptionLabel={(option) => option.offer_name}
            sx={{width: 350, marginTop: 3}}
            onChange={(event, values) => setOffer({name: values.offer_name, offer_id: values.offer_id})}
            renderInput={(params) => <TextField {...params} label="Choose an offer"/>}
          />

          <Box display="flex" justifyContent="start" mt="50px">
            <Button color="secondary" variant="contained" onClick={onSubmit}>
              Create New Campaign
            </Button>
          </Box>
        </Box>

      </Modal>
    </Box>
  )
}

export default ModalNewCampaign;
