import React, {useEffect, useRef, useState} from 'react';
import {Autocomplete, Box, CircularProgress, TextField, useTheme} from '@mui/material';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {requestConfig} from '../containers/utils';
import {tokens} from "../theme";

const UserSelect = ({currentUser}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const autocompleteRef = useRef(null);
  const [load, setLoad] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [teamLead, setTeamLead] = useState('');
  const [userList, setUserList] = useState([]);
  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);

  const clearLocalStorageForUser = () => {
    localStorage.setItem('masqueradeUser', '');
    localStorage.setItem('masqueradeTeam', '');
  };

  const getUserLabel = (user) => {
    const user_id = user.user_id ? user.user_id.split(',')[0].trim().substring(0, 3) + ' - ' : '';
    return user_id + user.email;
  }

  const get_employees = () => {
    if (currentUser.is_team_lead) {
      setLoad(true);
      axios.get(`${process.env.REACT_APP_API_URL}/api/user/team_lead_groups/`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            const data = response.data;
            data.sort((a, b) => a.name.localeCompare(b.name));

            data.forEach(group => {
              group.users.sort((a, b) => a.user_id.localeCompare(b.user_id, undefined, {numeric: true}));

              const teamLeadIndex = group.users.findIndex(emp => emp.id === currentUser.id);
              if (teamLeadIndex !== -1) {
                const [teamLeadItem] = group.users.splice(teamLeadIndex, 1);
                group.users.unshift(teamLeadItem);
              }
            });

            const groupedEmployees = [];

            const countGroups = data.filter(group => group.users.length > 1).length;
            if (countGroups > 1) {
              groupedEmployees.push({
                id: `*-*`,
                label: 'All - All Teams',
                userId: '*',
                groupId: '*',
                tag: 'ALL',
              });
            }

            data.forEach((group, index) => {
              if (group.users.length > 1) {
                groupedEmployees.push({
                  id: `*-${group.id}`,
                  label: group.name,
                  userId: '*',
                  groupId: group.id,
                  tag: 'GROUP',
                });
              }

              groupedEmployees.push(...group.users.map(user => ({
                id: `${user.id}-${group.id}`,
                label: getUserLabel(user),
                userId: user.id,
                groupId: group.id,
                tag: 'USER',
              })));
            });

            const teamLeadIndex = groupedEmployees.findIndex(emp => emp.userId === currentUser.id);
            if (teamLeadIndex === -1) {
              groupedEmployees.unshift({
                id: `${currentUser.id}-0`,
                label: getUserLabel(currentUser),
                userId: currentUser.id,
                groupId: 0,
                tag: 'USER',
              })
            }

            setUserList(groupedEmployees);
          } else {
            console.log(response.statusText);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          setLoad(false);
        });
    }
  };

  const handleSelectChange = (event, value) => {
    if (value) {
      localStorage.setItem('masqueradeUser', value.userId);
      localStorage.setItem('masqueradeTeam', value.groupId);
      dispatch({type: 'SET_SELECTED_EMPLOYEE', payload: value.label});
    }
  };

  const handleInputChange = (event, value, reason) => {
    if (reason === 'clear') {
      const selectedOption = userList.find(emp => emp.label === teamLead);
      if (selectedOption) {
        handleSelectChange(null, selectedOption);
      }
    }

    // Blur the input field after selection
    if (reason === 'reset' && autocompleteRef.current) {
      autocompleteRef.current.querySelector('input').blur();
    }
  };

  useEffect(() => {
    clearLocalStorageForUser(currentUser);

    get_employees();

    if (initialLoad) {
      setTeamLead(getUserLabel(currentUser))
      dispatch({type: 'SET_SELECTED_EMPLOYEE', payload: getUserLabel(currentUser)});
      setInitialLoad(false);
    }
  }, [currentUser.id, dispatch]);

  const renderOption = (props, option) => (
    <li
      {...props}
      key={option.id}
      style={{
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        padding: '8px',
        backgroundColor: option.tag === 'GROUP' ? colors.grey[900] : 'transparent',
        color: option.tag === 'GROUP' ? colors.grey[400] : 'inherit',
        fontWeight: option.tag === 'GROUP' ? 'bold' : 'normal',
        fontSize: option.tag === 'GROUP' ? '1rem' : 'inherit'
      }}
    >
      {option.label}
    </li>
  );

  return (
    <Box sx={{display: 'flex', alignItems: 'center', position: 'relative', padding: '8px'}}>
      {load && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: '-12px',
            marginTop: '-12px',
            zIndex: 1,
          }}
        />
      )}
      <Autocomplete
        options={userList}
        getOptionLabel={(option) => option.label}
        ref={autocompleteRef}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            sx={{
              width: '100%',
              minWidth: '250px',
              opacity: load ? 0.5 : 1,
              '.MuiOutlinedInput-notchedOutline': {border: 0},
              '& .MuiAutocomplete-popupIndicator': {
                display: selectedEmployee === teamLead ? 'visible' : 'none',
              },
            }}
            disabled={load}
            InputProps={{
              ...params.InputProps,
              sx: {
                display: 'flex',
                alignItems: 'center',
                boxSizing: 'border-box !important',
              },
            }}
            inputProps={{
              ...params.inputProps,
              sx: {
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: 0,
                boxSizing: 'unset !important',
              },
              style: {
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                color: selectedEmployee === teamLead ? undefined : '#F08080',
              },
            }}
          />
        )}
        renderOption={renderOption}
        onChange={handleSelectChange}
        onInputChange={handleInputChange}
        value={userList.find(emp => emp.label === selectedEmployee) || userList.find(emp => emp.label === teamLead) || null}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disableClearable={selectedEmployee === teamLead}
      />
    </Box>
  );
};

export default UserSelect;
