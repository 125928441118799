import {useState} from "react";
import axios from "axios";
import {Box, Button, IconButton, Modal, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from '@mui/icons-material/Close';
import {toast} from "react-toastify";

import Header from "../../components/Header";
import {formatErrorMessage, getModalCommonStyle, requestConfig} from "../utils";

const ModalNewOffer = (props) => {
  const [isButtonCreateDisabled, setButtonCreateDisabled] = useState(false);

  const templateDisplayToBackendMapping = {
    'Cross - Facebook': 'cross-facebook',
    'Sedo - Facebook': 'sedo-facebook',
    'Cross - TikTok': 'cross-tiktok',
    'Tonic - TikTok': 'tonic-tiktok',
    // 'Yahoo - Facebook': 'codefuel-facebook',
    // 'Yahoo - TikTok': 'codefuel-tiktok',
    'Ads - Facebook': 'ads-facebook',
    'Ads - TikTok': 'ads-tiktok',
    'Tonic 1 - Facebook': 'tonicTrackTonic-facebook',
    'Tonic 2 - Facebook': 'tonicCodefuel-facebook'
  };
  const choose_template = Object.keys(templateDisplayToBackendMapping);

  const styleModal = {
    ...getModalCommonStyle(),
    width: 470,
  };

  const onModalClose = () => {
    props.setOfferOpenCreate(false)
  }

  const onModalKeyDown = (event) => {
    if (event.key === "Escape") {
      onModalClose();
    }
  };

  const handleOfferCreateClick = async () => {
    await props.get_references('campaigns')
    props.setOfferCampaignId("")
    props.setOfferTitle("")
    props.setOfferAdText("")
    props.setOfferUrl("")
    props.setOfferKeyWords("")
    props.setOfferTemplate("")
    props.setOfferOpenCreate(true)
  };

  const handleOfferCreateSubmit = async () => {
    const data = {
      title: props.offerTitle,
      offer: props.offerAdText,
      template: templateDisplayToBackendMapping[props.offerTemplate] || props.offerTemplate,
      url: props.offerUrl,
      kw: props.offerKeyWords,
      campaign: props.offerCampaignId
    }

    setButtonCreateDisabled(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/redtrack/offers/`, data, requestConfig)
        .then(function (response) {
          if (response.status === 201) {
            props.setOfferOpenCreate(false);
            toast.success('Success! Tracker offer will be created in 5-20 seconds', {
              position: "top-center",
              autoClose: 3000
            });
          } else {
            toast.error(response.data)
          }
          props.get_campaigns();
        })
        .catch(function (error) {
          console.log(error);
          toast.error(formatErrorMessage(error));
        })
    } finally {
      setButtonCreateDisabled(false);
    }
  };

  return (
    <Box>
      <Box m="0 0 0 20px">
        <Button variant="contained" onClick={handleOfferCreateClick}>Create new Offer</Button>
      </Box>

      <Modal
        open={props.offerOpenCreate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static"
        onKeyDown={onModalKeyDown}
      >
        <Box sx={styleModal}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title="Create offer" subtitle="create new offer with add to split in campaign"/>

            <IconButton onClick={onModalClose} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>
          </Box>

          <TextField required id="outlined-basic" label="Title" variant="outlined"
                     sx={{width: 400, marginTop: 3}}
                     onChange={(event) => props.setOfferTitle(event.target.value)} value={props.offerTitle}/>

          <TextField required id="outlined-basic" label="Ad text" variant="outlined" sx={{width: 400, marginTop: 3}}
                     onChange={(event) => props.setOfferAdText(event.target.value)} value={props.offerAdText}/>

          <TextField required id="outlined-basic" label="Offer's url" variant="outlined" sx={{width: 400, marginTop: 3}}
                     onChange={(event) => props.setOfferUrl(event.target.value)} value={props.offerUrl}/>

          <TextField id="outlined-basic" label="Keywords separated by comma" variant="outlined"
                     sx={{width: 400, marginTop: 3}}
                     onChange={(event) => props.setOfferKeyWords(event.target.value)} value={props.offerKeyWords}/>

          <Autocomplete
            required={true}
            disablePortal
            id="combo-box-demo"
            options={choose_template}
            getOptionLabel={(option) => option}
            sx={{width: 400, marginTop: 3}}
            onChange={(event, values) => props.setOfferTemplate(values)}
            renderInput={(params) => <TextField {...params} label="Choose template offer link"/>}
            value={props.offerTemplate}
          />

          <Autocomplete
            disablePortal
            disableClearable
            id="combo-box-demo"
            options={props.campaigns}
            getOptionLabel={(option) => option.title + ' (#' + option.id + ')'}
            sx={{width: 400, marginTop: 3}}
            onChange={(event, values) => props.setOfferCampaignId(values.id_campaign)}
            renderInput={(params) => <TextField {...params} label="Campaign for add to split"/>}
            // value={props.offerCampaignTitle}
          />

          <Box display="flex" justifyContent="start" mt="50px">
            <Button color="secondary" variant="contained" onClick={handleOfferCreateSubmit}
                    disabled={isButtonCreateDisabled}>
              Create New Tracker Offer
            </Button>
          </Box>
        </Box>

      </Modal>
    </Box>
  )
}

export default ModalNewOffer;
