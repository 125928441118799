import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import axios from 'axios';
import {Box} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Header from "../../components/Header";
import {
  column_as_number,
  column_as_number_2_digits,
  column_as_number_3_digits,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig
} from "../utils";

const AdsOneDomainStatistic = ({isAuthenticated}) => {
  const routeParams = useParams();
  const cid = routeParams.cid;

  const [domains, setDomains] = useState([]);
  const [finals, setFinals] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [countries, setCountries] = useState([]);
  const [publishers, setPublishers] = useState([]);

  const [titleDomain, setTitleDomain] = useState("");
  const [sortModelEstimate, setSortModelEstimate] = useState([]);
  const [sortModelFinals, setSortModelFinals] = useState([]);
  const [sortModelKeywords, setSortModelKeywords] = useState([]);
  const [sortModelCountries, setSortModelCountries] = useState([]);
  const [sortModelPublishers, setSortModelPublishers] = useState([]);

  const startStr = useSelector(state => state.date.startDateStr);
  const endStr = useSelector(state => state.date.endDateStr);
  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('tab_estimate');
  const [load, setLoad] = useState(false);


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    get_domains();
  }, [activeTab, startStr, endStr, selectedEmployee]);

  useEffect(() => {
    handleTabChange(null, 'tab_estimate');
  }, []);


  const get_domains = () => {
    if (activeTab === 'tab_estimate') {
      axios.get(`${process.env.REACT_APP_API_URL}/api/ppads/domains/${cid}/`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            setTitleDomain(response.data.domain_name);
          } else {
            console.log(response.statusText);
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      setLoad(true);
      axios.get(`${process.env.REACT_APP_API_URL}/api/ppads/statistic_by_date/?domain=${cid}&start_date=${startStr}&end_date=${endStr}`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            setDomains(response.data);
          } else {
            console.log(response.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setLoad(false);
        });
    }

    if (activeTab === 'tab_finals') {
      setLoad(true);
      axios.get(`${process.env.REACT_APP_API_URL}/api/ppads/statistic_final_by_date/?domain=${cid}&start_date=${startStr}&end_date=${endStr}`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            setFinals(response.data);
          } else {
            console.log(response.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setLoad(false);
        });
    }

    if (activeTab === 'tab_keywords') {
      setLoad(true);
      axios.get(`${process.env.REACT_APP_API_URL}/api/ppads/statistic_keywords/?domain=${cid}&start_date=${startStr}&end_date=${endStr}`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            setKeywords(response.data);
          } else {
            console.log(response.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setLoad(false);
        });
    }

    if (activeTab === 'tab_countries') {
      setLoad(true);
      axios.get(`${process.env.REACT_APP_API_URL}/api/ppads/statistic_by_country/?domain=${cid}&start_date=${startStr}&end_date=${endStr}`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            setCountries(response.data);
          } else {
            console.log(response.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setLoad(false);
        });
    }

    if (activeTab === 'tab_publishers') {
      setLoad(true);
      axios.get(`${process.env.REACT_APP_API_URL}/api/ppads/statistic_by_publisher/?domain=${cid}&start_date=${startStr}&end_date=${endStr}`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            setPublishers(response.data);
          } else {
            console.log(response.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setLoad(false);
        });
    }
  };

  const columns_estimate = [
    {
      field: "date",
      headerName: "Date",
      cellClassName: "name-column--cell",
      aggregable: false,
    },
    {
      field: "domain_name",
      headerName: "Domain Name",
      flex: 0.3,
      cellClassName: "name-column--cell",
      aggregable: false,
    },
    {
      field: "profit",
      headerName: "Profit",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "roi",
      headerName: "ROI, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "spend",
      headerName: "Spend",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "visits",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "ctr",
      headerName: "CTR, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "epc",
      headerName: "EPC",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "clicks",
      headerName: "Conversions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
  ];

  const columns_finals = [
    {
      field: "date",
      headerName: "Date",
      cellClassName: "name-column--cell",
      aggregable: false,
    },
    {
      field: "domain_name",
      headerName: "Domain Name",
      flex: 0.3,
      cellClassName: "name-column--cell",
      aggregable: false,
    },
    {
      field: "visits",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "ctr",
      headerName: "CTR, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "epc",
      headerName: "EPC",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "clicks",
      headerName: "Conversions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
  ];

  const columns_keywords = [
    {
      field: "keywords",
      headerName: "Keywords",
      flex: 0.3,
      cellClassName: "name-column--cell",
    },
    {
      field: "epc",
      headerName: "EPC",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "clicks",
      headerName: "Conversions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
  ];

  const columns_countries = [
    {
      field: "country_code",
      headerName: "Country",
      flex: 0.3,
      cellClassName: "name-column--cell",
    },
    {
      field: "visits",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "ctr",
      headerName: "CTR, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "epc",
      headerName: "EPC",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "clicks",
      headerName: "Conversions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
  ];

  const columns_publishers = [
    {
      field: "ref_pubsite",
      headerName: "Publisher",
      flex: 0.3,
      cellClassName: "name-column--cell",
    },
    {
      field: "visits",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "ctr",
      headerName: "CTR, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "epc",
      headerName: "EPC",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "clicks",
      headerName: "Conversions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
  ]

  const handleTabChange = (event, newValue) => {
    if (activeTab === newValue) {
      return;
    }

    setActiveTab(newValue);

    if (activeTab !== 'tab_estimate') {
      setDomains([]);
    }
    if (activeTab !== 'tab_finals') {
      setFinals([]);
    }
    if (activeTab !== 'tab_keywords') {
      setKeywords([]);
    }
    if (activeTab !== 'tab_countries') {
      setCountries([]);
    }
    if (activeTab !== 'tab_publishers') {
      setPublishers([])
    }
  };

  const commonGridProps = {
    density: 'compact',
    hideFooterSelectedRowCount: true,
    disableColumnReorder: true,
    disableRowSelectionOnClick: true,
    disableColumnMenu: true,
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title={`Domain ${titleDomain}`} subtitle="statistic by domain"/>

      <Box m="0 0 20px 0">
        <Link to={`/`} onClick={() => navigate(-1)}><span style={{marginRight: '5px'}}>{'\u2190'}</span>Back</Link>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <TabContext value={activeTab}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab value="tab_estimate" label="Estimate statistic"/>
            <Tab value="tab_finals" label="Final statistic"/>
            <Tab value="tab_keywords" label="Statistic by keywords"/>
            <Tab value="tab_countries" label="Statistic by countries"/>
            <Tab value="tab_publishers" label="Statistic by publishers"/>
          </Tabs>

          <TabPanel value="tab_estimate"
                    sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px'}}>
            <DataGridPremium
              {...commonGridProps}
              rows={domains}
              columns={columns_estimate}
              getRowId={(row) => row.date}
              initialState={{
                aggregation: {
                  model: {
                    profit: 'sum',
                    roi: 'avg',
                    spend: 'sum',
                    visits: 'sum',
                    clicks: 'sum',
                    epc: 'avg',
                    revenue: 'sum',
                  },
                },
                sorting: {
                  sortModel: sortModelEstimate,
                },
              }}
              onSortModelChange={(newModel) => {
                setSortModelEstimate(newModel);
              }}
            />
          </TabPanel>

          <TabPanel value="tab_finals"
                    sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px'}}>
            <DataGridPremium
              {...commonGridProps}
              rows={finals}
              columns={columns_finals}
              getRowId={(row) => row.date}
              initialState={{
                aggregation: {
                  model: {
                    visits: 'sum',
                    epc: 'avg',
                    clicks: 'sum',
                    revenue: 'sum',
                  },
                },
                sorting: {
                  sortModel: sortModelFinals,
                },
              }}
              onSortModelChange={(newModel) => {
                setSortModelFinals(newModel);
              }}
            />
          </TabPanel>

          <TabPanel value="tab_keywords"
                    sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px'}}>
            <DataGridPremium
              {...commonGridProps}
              rows={keywords}
              columns={columns_keywords}
              getRowId={(row) => row.keywords}
              initialState={{
                aggregation: {
                  model: {
                    epc: 'avg',
                    revenue: 'sum',
                    clicks: 'sum'
                  },
                },
                sorting: {
                  sortModel: sortModelKeywords,
                },
              }}
              onSortModelChange={(newModel) => {
                setSortModelKeywords(newModel);
              }}
            />
          </TabPanel>

          <TabPanel value="tab_countries"
                    sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px'}}>
            <DataGridPremium
              {...commonGridProps}
              rows={countries}
              columns={columns_countries}
              getRowId={(row) => row.country_code}
              initialState={{
                aggregation: {
                  model: {
                    visits: 'sum',
                    clicks: 'sum',
                    epc: 'avg',
                    revenue: 'sum',
                  },
                },
                sorting: {
                  sortModel: sortModelCountries,
                },
              }}
              onSortModelChange={(newModel) => {
                setSortModelCountries(newModel);
              }}
            />
          </TabPanel>

          <TabPanel value="tab_publishers"
                    sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px'}}>
            <DataGridPremium
              {...commonGridProps}
              rows={publishers}
              columns={columns_publishers}
              getRowId={(row) => row.ref_pubsite}
              initialState={{
                aggregation: {
                  model: {
                    visits: 'sum',
                    clicks: 'sum',
                    epc: 'avg',
                    revenue: 'sum',
                  },
                },
                sorting: {
                  sortModel: sortModelPublishers,
                },
              }}
              onSortModelChange={(newModel) => {
                setSortModelPublishers(newModel);
              }}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  )
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(AdsOneDomainStatistic);
