import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import axios from "axios";
import {Box, Button} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import Header from "../../components/Header";
import RefreshIcon from "@mui/icons-material/Refresh";
import {SearchInput, searchLoadText} from "../../search";
import {
  column_as_number,
  column_as_number_2_digits,
  column_as_number_3_digits,
  convertSortModelToDjangoSort,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig,
} from "../utils";

const YahooCodefuelChanelStatistic = ({isAuthenticated, account}) => {
  const navigate = useNavigate();
  const searchStorage = YahooCodefuelChanelStatistic.name + account;

  const [load, setLoad] = useState(false);
  const [statistics, setStatistics] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [searchText, setSearchText] = useState(() => searchLoadText(searchStorage));
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });
  const [sortModel, setSortModel] = useState([]);

  const [totals, setTotals] = useState({
    visits: 0,
    clicks: 0,
    revenue: 0.00,
    ctr: 0.00,
    epc: 0.000,
  });

  const startStr = useSelector((state) => state.date.startDateStr);
  const endStr = useSelector((state) => state.date.endDateStr);
  const selectedEmployee = useSelector((state) => state.employee.selectedEmployee);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    getStatistics();
  }, [startStr, endStr, selectedEmployee, searchText, paginationModel, sortModel]);

  const getStatistics = () => {
    const ordering = convertSortModelToDjangoSort(sortModel).join(',');
    setLoad(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/yahoo/statistic/?account=${account}&start_date=${startStr}&end_date=${endStr}&search=${searchText}&page=${paginationModel.page + 1}&page_size=${paginationModel.pageSize}&ordering=${ordering}`,
        requestConfig
      )
      .then((response) => {
        if (response.status === 200) {
          const {results, count, totals} = response.data;
          setStatistics(results || []);
          setRowCount(count || 0);
          setTotals(totals || {
            visits: 0,
            clicks: 0,
            revenue: 0.00,
            ctr: 0.00,
            epc: 0.000,
          });
        } else {
          console.log(response.statusText);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const columns = [
    {field: "asset_gid", headerName: "Account", flex: 0.1},
    {
      field: "channel",
      headerName: "Channel",
      flex: 0.2,
      renderCell: (params) => (
        <Link to={`/codefuel/statistic/YAHOO/${params.row.channel}`}>
          {params.value}
        </Link>
      ),
    },
    {field: "visits", headerName: "Clicks", flex: 0.1, ...column_as_number},
    {field: "ctr", headerName: "CTR, %", flex: 0.1, ...column_as_number_2_digits},
    {field: "epc", headerName: "EPC", flex: 0.1, ...column_as_number_3_digits},
    {field: 'clicks', headerName: 'Conversions', flex: 0.1, ...column_as_number},
    {field: "revenue", headerName: "Revenue", flex: 0.1, ...column_as_number_2_digits},
  ];

  return (
    <Box sx={{display: "flex", flexDirection: "column", height: "calc(100% - 16px)"}}>
      <Header title="Yahoo" subtitle="statistic by channels"/>
      <Box sx={{display: "flex", alignItems: "center", margin: "-5px 0 15px 0", minHeight: "38px", width: "100%"}}>
        <SearchInput
          storagePath={searchStorage}
          onChange={(value) => setSearchText(value)}
          inputProps={{sx: {height: "36px", padding: "0 14px"}}}
          sx={{height: "36px", maxWidth: "600px", flex: 1}}
        />
        <Box sx={{flex: 1}}/>
        <Button sx={{padding: "7px", minWidth: 0}} variant="contained" onClick={getStatistics}>
          <RefreshIcon/>
        </Button>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: "flex", flexDirection: "column", flex: 1, overflow: "auto", ...getDataGridCommonStyle()}}>
        <DataGridPremium
          density="compact"
          rows={statistics}
          columns={columns}
          rowCount={rowCount}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          pageSizeOptions={[100]}
          onPaginationModelChange={(newModel) => {
            setPaginationModel(newModel);
          }}
          sortingMode="server"
          onSortModelChange={(newModel) => {
            setSortModel(newModel);
          }}
          getRowId={(row) => `${row.asset_gid}-${row.channel}`}
          pinnedRows={{
            bottom: [{
              id: 'total',
              visits: totals.visits,
              clicks: totals.clicks,
              revenue: totals.revenue,
              ctr: "", // CTR is an average, so it’s displayed as calculated
              epc: totals.epc,
              asset_gid: '',
            }]
          }}
          disableAggregation
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state, props) => ({
  isAuthenticated: state.auth.isAuthenticated,
  account: props.account || "YAHOO",
});

export default connect(mapStateToProps)(YahooCodefuelChanelStatistic);
