import {Link, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import axios from 'axios';

import {Box} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import Header from "../../components/Header";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";

import {
  column_as_number,
  column_as_number_2_digits,
  column_as_number_3_digits,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig
} from "../utils";


const Tonic2OneCampaignStatistic = ({isAuthenticated, account}) => {
  const routeParams = useParams();
  const cid = routeParams.cid;

  const [campaigns, setCampaigns] = useState([]);
  const [keywords, setKeywords] = useState([])

  const [titleCampaign, setTitleCampaign] = useState("");
  const [sortModel, setSortModel] = useState([]);

  const startStr = useSelector(state => state.date.startDateStr);
  const endStr = useSelector(state => state.date.endDateStr);
  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);

  const navigate = useNavigate();
  const [load, setLoad] = useState(false)
  const [activeTab, setActiveTab] = useState('tab_campaigns');


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    get_campaigns()
  }, [activeTab, startStr, endStr, selectedEmployee]);

  useEffect(() => {
    handleTabChange(null, 'tab_campaigns')
  }, []);


  const get_campaigns = () => {
    if (activeTab === 'tab_campaigns') {
      axios.get(`${process.env.REACT_APP_API_URL}/api/codefuel/campaigns/${cid}/?account=${account}`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            setTitleCampaign(response.data.campaign_name)
          } else {
            console.log(response.data)
          }
        })
        .catch(function (error) {
          console.log(error);
        })

      setLoad(true)
      axios.get(`${process.env.REACT_APP_API_URL}/api/codefuel/statistic_by_date/?account=${account}&campaign=${cid}&start_date=${startStr}&end_date=${endStr}`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            setCampaigns(response.data)
          } else {
            console.log(response.data)
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setLoad(false);
        })
    }

    if (activeTab === 'tab_keywords') {
      axios.get(`${process.env.REACT_APP_API_URL}/api/codefuel/statistic_by_keywords/?account=${account}&campaign=${cid}&start_date=${startStr}&end_date=${endStr}`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            setKeywords(response.data)
          } else {
            console.log(response.data)
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setLoad(false);
        })
    }
  }

  const handleTabChange = (event, newValue) => {
    if (activeTab === newValue) {
      return;
    }

    setActiveTab(newValue);

    if (activeTab !== 'tab_campaigns') {
      setCampaigns([])
    }
    if (activeTab !== 'tab_keywords') {
      setKeywords([])
    }
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      cellClassName: "name-column--cell",
      aggregable: false,
    },
    {
      field: "campaign_name",
      headerName: "Campaign Name",
      flex: 0.3,
      aggregable: false,
      cellClassName: "name-column--cell",
    },
    {
      field: "profit",
      headerName: "Profit",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "roi",
      headerName: "ROI, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "spend",
      headerName: "Spend",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "visits",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "ctr",
      headerName: "CTR, %",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
    {
      field: "epc",
      headerName: "EPC",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "clicks",
      headerName: "Conversions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
  ]

  const columns_keywords = [
    {
      field: "keyword",
      headerName: "Keyword",
      flex: 0.3,
      aggregable: false,
      cellClassName: "name-column--cell",
    },
    {
      field: "epc",
      headerName: "EPC",
      flex: 0.1,
      ...column_as_number_3_digits,
    },
    {
      field: "clicks",
      headerName: "Conversions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 0.1,
      ...column_as_number_2_digits,
    },
  ]

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title={`Campaign ${titleCampaign}`} subtitle="statistic by campaign"/>

      <Box m="0 0 20px 0">
        <Link to="/" onClick={() => navigate(-1)}><span style={{marginRight: '5px'}}>{'\u2190'}</span>Back</Link>
      </Box>

      <ModalCircularProgress load={load}/>

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <TabContext value={activeTab}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab value="tab_campaigns" label="Total statistic"/>
            <Tab value="tab_keywords" label="Statistic by keywords"/>
          </Tabs>

          <TabPanel value="tab_campaigns"
                    sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px'}}>
            <DataGridPremium
              rows={campaigns}
              columns={columns}
              getRowId={(row) => row.date}
              initialState={{
                aggregation: {
                  model: {
                    profit: 'sum',
                    roi: 'avg',
                    spend: 'sum',
                    visits: 'sum',
                    clicks: 'sum',
                    epc: 'avg',
                    revenue: 'sum',
                  },
                },
                sorting: {
                  sortModel: sortModel,
                },
              }}
              onSortModelChange={(newModel) => {
                setSortModel(newModel);
              }}
              density="compact"
              hideFooterSelectedRowCount={true}
              disableColumnReorder
              disableRowSelectionOnClick
              disableColumnMenu={true}
            />
          </TabPanel>
          <TabPanel value="tab_keywords"
                    sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px'}}>
            <DataGridPremium
              rows={keywords}
              columns={columns_keywords}
              getRowId={(row) => row.keyword}
              initialState={{
                aggregation: {
                  model: {
                    epc: 'avg',
                    clicks: 'sum',
                    revenue: 'sum',
                  },
                },
                sorting: {
                  sortModel: sortModel,
                },
              }}
              onSortModelChange={(newModel) => {
                setSortModel(newModel);
              }}
              density="compact"
              hideFooterSelectedRowCount={true}
              disableColumnReorder
              disableRowSelectionOnClick
              disableColumnMenu={true}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  )
};

const mapStateToProps = (state, props) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    account: props.account || "TONIC2",
  }
};

export default connect(mapStateToProps)(Tonic2OneCampaignStatistic);
