import React, {useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import {ColorModeContext, useMode} from "./theme";
import {CssBaseline, ThemeProvider} from "@mui/material";

import Login from "./containers/Login";
import Signup from "./containers/Signup";
import Activate from "./containers/Activate";
import ResetPassword from "./containers/ResetPassword";
import ResetPasswordConfirm from "./containers/ResetPasswordConfirm";

import AdsDomainsStatistic from "./containers/Ads/AdsDomainsStatistic";
import AdsOneDomainStatistic from "./containers/Ads/AdsOneDomainStatistic";
import AdsDomains from "./containers/Ads/AdsDomains";

import TonicCampaignsStatistic from "./containers/Tonic/TonicCampaignsStatistic";
import TonicOneCampaignStatistic from "./containers/Tonic/TonicOneCampaignStatistic";
import TonicCampaigns from "./containers/Tonic/TonicCampaigns";
import setKeywords from "./containers/Tonic/setKeywords";

import TikTokCampaigns from "./containers/TikTok/TikTokCampaigns";
import TikTokAdvertiser from "./containers/TikTok/TikTokAdvertiser";
import TikTokAdgroup from "./containers/TikTok/TikTokAdgroup";
import TikTokConnect from "./containers/TikTok/TikTokConnect";

import FacebookAdAccounts from "./containers/Facebook/FacebookAdAccounts";
import FacebookCampaigns from "./containers/Facebook/FacebookCampaigns";
import FacebookAdgroup from "./containers/Facebook/FacebookAdgroup";
import FacebookCallback from "./containers/Facebook/FacebookCallback";
import FacebookUserTokens from "./containers/Facebook/FacebookUserTokens";
import FacebookCallbackRefresh from "./containers/Facebook/FacebookCallbackRefresh";

import TaboolaAdvertiser from "./containers/Taboola/TaboolaAdvertiser";
import TaboolaCampaigns from "./containers/Taboola/TaboolaCampaigns";
import TaboolaSites from "./containers/Taboola/TaboolaSites";

import OutBrainMarketer from "./containers/OutBrain/OutBrainMarketer";
import OutBrainCampaigns from "./containers/OutBrain/OutBrainCampaigns";
import OutBrainPublishers from "./containers/OutBrain/OutBrainPublishers";

import ZemantaAdvertiser from "./containers/Zemanta/ZemantaAdvertiser";
import ZemantaCampaigns from "./containers/Zemanta/ZemantaCampaigns";
import ZemantaAdGroups from "./containers/Zemanta/ZemantaAdGroups";
import ZemantaPublishers from "./containers/Zemanta/ZemantaPublishers";

import CrossCampaignsStatistic from "./containers/Cross/CrossCampaignsStatistic";
import CrossStatisticOneCampaign from "./containers/Cross/CrossStatisticOneCampaign";
import CrossCampaigns from "./containers/Cross/CrossCampaigns";

import SedoDomainsStatistic from "./containers/Sedo/SedoDomainsStatistic";
import SedoOneDomainStatistic from "./containers/Sedo/SedoOneDomainStatistic";
import SedoDomains from "./containers/Sedo/SedoDomains";

import Tonic2CampaignsStatistic from "./containers/CodeFuel/Tonic2CampaignsStatistic";
import Ads2CampaignsStatistic from "./containers/CodeFuel/Ads2CampaignsStatistic";
import Tonic2OneCampaignStatistic from "./containers/CodeFuel/Tonic2OneCampaignStatistic";
import Ads2OneCampaignStatistic from "./containers/CodeFuel/Ads2OneCampaignStatistic";
import Tonic2Campaigns from "./containers/CodeFuel/Tonic2Campaigns";
import Ads2Campaigns from "./containers/CodeFuel/Ads2Campaigns";
import YahooCodefuelChanelStatistic from "./containers/Yahoo/YahooCodefuelChanelStatistic"
import YahooCodefuelChanels from "./containers/Yahoo/YahooCodefuelChanels";
import YahooCodefuelOneStatistic from "./containers/Yahoo/YahooCodefuelOneStatistic"

import SystemOneDomains from "./containers/SystemOne/SystemOneDomains";
import SystemOneStatistics from "./containers/SystemOne/SystemOneStatistics";
import SystemOneDomainStatistics from "./containers/SystemOne/SystemOneDomainStatistics";

import RSOCCodeFuelStatistic from "./containers/RSOC/RSOCCodeFuelStatistic";
import RSOC2Statistic from "./containers/RSOC2/RSOC2Statistic";

import ClickFlareMain from "./containers/ClickFlare/ClickFlareMain";
import ClickFlareStatistic from "./containers/ClickFlare/ClickFlareStatistic";

import RedtrackMain from "./containers/Redtrack/RedtrackMain";
import RedTrackStatistic from "./containers/Redtrack/RedTrackStatistic";

import Profile from "./containers/Profile/Profile";
import Dashboard from "./containers/dashboard/Dashboard";
import DashboardAdmin from "./containers/dashboard/DashboardAdmin";
import KeywordsSearch from "./containers/Keywords/KeywordsSearch";

import DomainsMain from "./containers/Domains/DomainsMain";

import {Provider} from "react-redux";
import store from "./store";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Layout from "./hocs/Layout";
import AuthProvider from "./hocs/AuthContext";
import Sidebar from "./components/Sidebar";

const App = () => {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <CssBaseline/>
          <ToastContainer/>
          <AuthProvider>
            <BrowserRouter>
              <div className="app">
                <Sidebar isSidebar={isSidebar}/>
                <main className="content">
                  <Layout>
                    <Routes>
                      <Route exact path="/" Component={Login}/>
                      <Route exact path="/profile/:cid" Component={Profile}/>
                      <Route exact path="/dashboard/admin" Component={DashboardAdmin}/>
                      <Route exact path="/dashboard/:cid" Component={Dashboard}/>

                      <Route exact path="/keywords" Component={KeywordsSearch}/>

                      <Route exact path="/signup" Component={Signup}/>
                      <Route exact path="/reset_password" Component={ResetPassword}/>
                      <Route exact path="/password/reset/confirm/:uid/:token" Component={ResetPasswordConfirm}/>
                      <Route exact path="/activate/:uid/:token" Component={Activate}/>

                      <Route exact path="/ads/statistic" Component={AdsDomainsStatistic}/>
                      <Route exact path="/ads/statistic/:cid" Component={AdsOneDomainStatistic}/>
                      <Route exact path="/ads/domains" Component={AdsDomains}/>

                      <Route exact path="/tonic/statistic" Component={TonicCampaignsStatistic}/>
                      <Route exact path="/tonic/statistic/:cid" Component={TonicOneCampaignStatistic}/>
                      <Route exact path="/tonic/campaigns" Component={TonicCampaigns}/>
                      <Route exact path="/tonic/campaigns/keywords/:cid" Component={setKeywords}/>

                      <Route exact path="/tiktok/advertiser" Component={TikTokAdvertiser}/>
                      <Route exact path="/tiktok/advertiser/:cid" Component={TikTokCampaigns}/>
                      <Route exact path="/tiktok/campaign/:cid" Component={TikTokAdgroup}/>
                      <Route exact path="/tiktok/connect/" Component={TikTokConnect}/>

                      <Route exact path="/facebook/adaccounts" Component={FacebookAdAccounts}/>
                      <Route exact path="/facebook/adaccount/:cid" Component={FacebookCampaigns}/>
                      <Route exact path="/facebook/campaign/:cid" Component={FacebookAdgroup}/>
                      <Route exact path="/facebook/callback/" Component={FacebookCallback}/>
                      <Route exact path="/facebook/callback_refresh/" Component={FacebookCallbackRefresh}/>
                      <Route exact path="/facebook/user/tokens" Component={FacebookUserTokens}/>

                      <Route exact path="/taboola/advertiser" Component={TaboolaAdvertiser}/>
                      <Route exact path="/taboola/advertiser/:cid" Component={TaboolaCampaigns}/>
                      <Route exact path="/taboola/campaign/:cid" Component={TaboolaSites}/>


                      <Route exact path="/outbrain/marketer" Component={OutBrainMarketer}/>
                      <Route exact path="/outbrain/marketer/:cid" Component={OutBrainCampaigns}/>
                      <Route exact path="/outbrain/campaign/:cid" Component={OutBrainPublishers}/>

                      <Route exact path="/zemanta/advertiser" Component={ZemantaAdvertiser}/>
                      <Route exact path="/zemanta/advertiser/:cid" Component={ZemantaCampaigns}/>
                      <Route exact path="/zemanta/campaign/:cid" Component={ZemantaAdGroups}/>
                      <Route exact path="/zemanta/adgroup/:cid" Component={ZemantaPublishers}/>

                      <Route exact path="/cross/statistic" Component={CrossCampaignsStatistic}/>
                      <Route exact path="/cross/statistic/:cid" Component={CrossStatisticOneCampaign}/>
                      <Route exact path="/cross/campaigns" Component={CrossCampaigns}/>

                      <Route exact path="/sedo/statistic" Component={SedoDomainsStatistic}/>
                      <Route exact path="/sedo/statistic/:cid" Component={SedoOneDomainStatistic}/>
                      <Route exact path="/sedo/domains" Component={SedoDomains}/>

                      <Route exact path="/codefuel/statistic/TONIC2" Component={Tonic2CampaignsStatistic}/>
                      <Route exact path="/codefuel/statistic/ADS2" Component={Ads2CampaignsStatistic}/>
                      <Route exact path="/codefuel/statistic/TONIC2/:cid" Component={Tonic2OneCampaignStatistic}/>
                      <Route exact path="/codefuel/statistic/ADS2/:cid" Component={Ads2OneCampaignStatistic}/>
                      <Route exact path="/codefuel/campaigns/TONIC2" Component={Tonic2Campaigns}/>
                      <Route exact path="/codefuel/campaigns/ADS2" Component={Ads2Campaigns}/>

                      <Route exact path="/codefuel/statistic/YAHOO" Component={YahooCodefuelChanelStatistic}/>
                      <Route exact path="/codefuel/campaigns/YAHOO" Component={YahooCodefuelChanels}/>
                      <Route exact path="/codefuel/statistic/YAHOO/:cid" Component={YahooCodefuelOneStatistic}/>


                      <Route exact path="/rsoc/codefuel/statistic" Component={RSOCCodeFuelStatistic}/>
                      <Route exact path="/rsoc2/statistic" Component={RSOC2Statistic}/>

                      <Route exact path="/clickflare" Component={ClickFlareMain}/>
                      <Route exact path="/clickflare/statistic/:cid" Component={ClickFlareStatistic}/>

                      <Route exact path="/redtrack" Component={RedtrackMain}/>
                      <Route exact path="/redtrack/statistic/:cid" Component={RedTrackStatistic}/>

                      <Route exact path="/system1/domains" Component={SystemOneDomains}/>
                      <Route exact path="/system1/statistic" Component={SystemOneStatistics}/>
                      <Route exact path="/system1/statistics/:cid" Component={SystemOneDomainStatistics}/>

                      <Route exact path="/domains/" Component={DomainsMain}/>

                    </Routes>
                  </Layout>
                </main>
              </div>
            </BrowserRouter>
          </AuthProvider>
        </Provider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
