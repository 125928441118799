import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {connect, useSelector} from 'react-redux';
import {Box, Button, Grid, IconButton, Modal} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import {toast} from "react-toastify";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy} from '@fortawesome/free-solid-svg-icons';
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import Header from "../../components/Header";
import ModalNewCampaign from "./ModalNewCampaign";
import ModalEditCampaign from "./ModalEditCampaign";
import ModalNewOffer from "./ModalNewOffer";
import ModalEditOffer from "./ModalEditOffer";
import ModalEditSource from "./ModalEditSource";
import RefreshIcon from "@mui/icons-material/Refresh";
import {SearchInput, searchLoadText} from "../../search";
import {
  column_as_number,
  convertSortModelToDjangoSort,
  formatErrorMessage,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig
} from "../utils";
import {DataGridPremium} from "@mui/x-data-grid-premium";

const ClickFlareMain = ({isAuthenticated}) => {
  const navigate = useNavigate();
  const searchStorage = ClickFlareMain.name;
  const [load, setLoad] = useState(false);

  const [campaigns, setCampaigns] = useState([]);
  const [offers, setOffers] = useState([]);
  const [sources, setSources] = useState([]);
  const [conversions, setConversions] = useState([]);

  const [campaignCreateData, setCampaignCreateData] = useState(null);

  const [offerCreateData, setOfferCreateData] = useState(null);
  const [offerDeleteData, setOfferDeleteData] = useState({id: '', title: ''});
  const [offerOpenDelete, setOfferOpenDelete] = useState(false);

  const [sourceDeleteData, setSourceDeleteData] = useState({id: '', title: ''});
  const [sourceOpenDelete, setSourceOpenDelete] = useState(false);

  const [activeTab, setActiveTab] = useState('tab_campaigns');
  const [sortModelCampaigns, setSortModelCampaigns] = useState([]);
  const [sortModelOffers, setSortModelOffers] = useState([]);
  const [sortModelSources, setSortModelSources] = useState([]);
  const [rowCountCampaigns, setRowCountCampaigns] = useState(0);
  const [rowCountOffers, setRowCountOffers] = useState(0);
  const [rowCountSources, setRowCountSources] = useState(0);
  const [paginationModelCampaigns, setPaginationModelCampaigns] = useState({pageSize: 100, page: 0});
  const [paginationModelOffers, setPaginationModelOffers] = useState({pageSize: 100, page: 0});
  const [paginationModelSources, setPaginationModelSources] = useState({pageSize: 100, page: 0});
  const [searchText, setSearchText] = useState(() => {
    return searchLoadText(searchStorage);
  });

  const startStr = useSelector(state => state.date.startDateStr);
  const endStr = useSelector(state => state.date.endDateStr);
  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);

  const offer_templates = [
    {pp: 'ads', id: 'ads-facebook', value: 'Ads - Facebook'},
    {pp: 'ads', id: 'ads-tiktok', value: 'Ads - TikTok'},
    {pp: 'ads', id: 'ads-taboola', value: 'Ads - Taboola '},
    {pp: 'ads', id: 'ads-outbrain', value: 'Ads - Outbrain '},
    {pp: 'ads', id: 'ads-zemanta', value: 'Ads - Zemanta '},
    {pp: 'cross', id: 'cross-facebook', value: 'Cross - Facebook'},
    {pp: 'cross', id: 'cross-tiktok', value: 'Cross - TikTok'},
    {pp: 'cross', id: 'cross-taboola', value: 'Cross - Taboola '},
    {pp: 'cross', id: 'cross-outbrain', value: 'Cross - Outbrain '},
    {pp: 'sedo', id: 'sedo-taboola', value: 'Sedo - Taboola'},
    {pp: 'sedo', id: 'sedo-outbrain', value: 'Sedo - Outbrain'},
    {pp: 'sedo', id: 'sedo-zemanta', value: 'Sedo - Zemanta'},
    {pp: 'tonic', id: 'tonic-taboola', value: 'Tonic 1 - Taboola'},
    {pp: 'tonic', id: 'tonic-outbrain', value: 'Tonic 1 - Outbrain'},
    {pp: 'codefuel', id: 'codefuel-facebook', value: 'Tonic 2 + Ads 2 - Facebook'},
    {pp: 'codefuel', id: 'codefuel-tiktok', value: 'Tonic 2 + Ads 2 - TikTok'},
    {pp: 'codefuel', id: 'codefuel-taboola', value: 'Tonic 2 + Ads 2 - Taboola'},
    {pp: 'codefuel', id: 'codefuel-outbrain', value: 'Tonic 2 + Ads 2 - Outbrain'},
    {pp: 'system1', id: 'system1-outbrain', value: 'System1 - Outbrain'},
    {pp: 'system1', id: 'system1-taboola', value: 'System1 - Taboola'},
    {pp: 'system1', id: 'system1-zemanta', value: 'System1 - Zemanta'},
    {pp: 'rsoc_codefuel', id: 'rsoc_codefuel-facebook', value: 'RSOC - Facebook'},
    {pp: 'rsoc_codefuel', id: 'rsoc_codefuel-tiktok', value: 'RSOC - TikTok'},
  ];

  const source_events = ['Purchase', 'ViewContent', 'Lead', 'Search', 'InitiateCheckout', 'CompletePayment'];


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/clickflare/conversions/`, requestConfig)
      .then(function (response) {
        setConversions(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setPaginationModelCampaigns(prev => ({...prev, page: 0}));
    setPaginationModelOffers(prev => ({...prev, page: 0}));
    setPaginationModelSources(prev => ({...prev, page: 0}));
  }, [startStr, endStr, selectedEmployee, searchText]);

  useEffect(() => {
    get_campaigns();
  }, [activeTab, paginationModelCampaigns, paginationModelOffers, paginationModelSources, sortModelCampaigns, sortModelOffers, sortModelSources]);


  const get_campaigns = () => {
    if (activeTab === 'tab_campaigns') {

      const ordering = convertSortModelToDjangoSort(sortModelCampaigns).join(',');

      setLoad(true);
      axios.get(`${process.env.REACT_APP_API_URL}/api/clickflare/campaigns/?page=${paginationModelCampaigns.page + 1}&page_size=${paginationModelCampaigns.pageSize}&start_date=${startStr}&end_date=${endStr}&search=${searchText}&ordering=${ordering}`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            const {results, count} = response.data;
            setCampaigns(results);
            setRowCountCampaigns(count);
          } else {
            console.log(response.statusText);
          }
          setLoad(false);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setLoad(false);
        });
    }

    if (activeTab === 'tab_offers') {
      const ordering = convertSortModelToDjangoSort(sortModelOffers).join(',');

      setLoad(true);
      axios.get(`${process.env.REACT_APP_API_URL}/api/clickflare/offers/?page=${paginationModelOffers.page + 1}&page_size=${paginationModelOffers.pageSize}&search=${searchText}&ordering=${ordering}`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            const {results, count} = response.data;
            setOffers(results);
            setRowCountOffers(count);
          } else {
            console.log(response.statusText);
          }
          setLoad(false);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setLoad(false);
        });
    }

    if (activeTab === 'tab_sources') {
      const ordering = convertSortModelToDjangoSort(sortModelSources).join(',');

      setLoad(true);
      axios.get(`${process.env.REACT_APP_API_URL}/api/clickflare/sources/?page=${paginationModelSources.page + 1}&page_size=${paginationModelSources.pageSize}&search=${searchText}&ordering=${ordering}`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            const {results, count} = response.data;
            setSources(results);
            setRowCountSources(count);
          } else {
            console.log(response.statusText);
          }
          setLoad(false);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setLoad(false);
        });
    }
  };

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleCampaignOfferLinkClick = (text) => {
    // navigator.clipboard.writeText(params);
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
    toast.success("Link copied", {position: "top-center", autoClose: 2000});
  };

  const handleTabChange = (event, newValue) => {
    if (activeTab === newValue) {
      return;
    }

    setActiveTab(newValue);

    if (activeTab !== 'tab_campaigns') {
      setCampaigns([]);
    }
    if (activeTab !== 'tab_offers') {
      setOffers([]);
    }
    if (activeTab !== 'tab_sources') {
      setSources([]);
    }
  };

  const handleOfferCloseDelete = () => {
    setOfferOpenDelete(false);
  };

  const handleOfferDeleteRequest = async () => {
    setLoad(true);
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/clickflare/offers/${offerDeleteData.id}/`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          console.log(response.data);
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(formatErrorMessage(error));
      })
      .finally(function () {
        setOfferOpenDelete(false);
        get_campaigns();
        setLoad(false);
      });
  };

  const modalOfferDeleteWindow = (status) => {
    return (
      <Modal
        open={status}
        onClose={handleOfferCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <IconButton onClick={handleOfferCloseDelete} sx={{position: "absolute", top: 15, right: 15,}}>
            <CloseIcon/>
          </IconButton>
          <Header title="Delete Offer?" subtitle={offerDeleteData.title}/>
          <Grid container sx={{display: 'flex', justifyContent: 'flex-end', gap: '20px'}}>
            <Grid>
              <Button variant="contained" color="success" onClick={handleOfferDeleteRequest}>Yes</Button>
            </Grid>
            <Grid>
              <Button variant="contained" color="error" onClick={handleOfferCloseDelete}>No</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    );
  };

  const handleSourceCloseDelete = () => {
    setSourceOpenDelete(false);
  };

  const handleSourceDeleteRequest = async () => {
    setLoad(true);
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/clickflare/sources/${sourceDeleteData.id}/`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          console.log(response.data);
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(formatErrorMessage(error));
      })
      .finally(function () {
        setSourceOpenDelete(false);
        get_campaigns();
        setLoad(false);
      });
  };

  const modalSourceDeleteWindow = (status) => {
    return (
      <Modal
        open={status}
        onClose={handleSourceCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <IconButton onClick={handleSourceCloseDelete} sx={{position: "absolute", top: 15, right: 15,}}>
            <CloseIcon/>
          </IconButton>
          <Header title="Delete Source?" subtitle={sourceDeleteData.title}/>
          <Grid container sx={{display: 'flex', justifyContent: 'flex-end', gap: '20px'}}>
            <Grid>
              <Button variant="contained" color="success" onClick={handleSourceDeleteRequest}>Yes</Button>
            </Grid>
            <Grid>
              <Button variant="contained" color="error" onClick={handleSourceCloseDelete}>No</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    )
  }

  const columns_campaign = [
    {
      field: '_copy',
      align: 'center',
      headerAlign: 'center',
      headerName: '',
      flex: 0.01,
      sortable: false,
      renderCell: (params) => (
        <button
          onClick={() => setCampaignCreateData(params.row)}
          style={{
            width: '20px',
            height: '20px',
            padding: 0,
            border: 'none',
            background: 'transparent',
            cursor: 'pointer',
          }}
        >
          <FontAwesomeIcon icon={faCopy} size="lg" style={{color: "#ffffff",}}/>
        </button>
      ),
    },
    {
      field: "created",
      align: 'center',
      headerAlign: 'center',
      headerName: "Created",
      flex: 0.1,
    },
    {
      field: "name",
      headerName: "Campaign Name",
      flex: 0.5,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Link to={`/clickflare/statistic/${params.row.id}`}>{params.value}</Link>
        )
      }
    },
    {
      field: "clicks",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "conversions",
      headerName: "Conversions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "url",
      headerName: "Tracker Link",
      flex: 0.5,
      renderCell: (params) => (
        <div className="offerlink" onClick={() => {
          handleCampaignOfferLinkClick(params.value)
        }}>{params.value}</div>
      )
    },
    {
      field: "splits",
      headerName: "Splits",
      flex: 0.075,
      ...column_as_number,
    },
    {
      field: "_edit",
      align: 'center',
      headerAlign: 'center',
      headerName: "Edit Campaign",
      flex: 0.1,
      sortable: false,
      renderCell: (params) => (
        <ModalEditCampaign
          campaign_id={params.row.id}
          get_campaigns={get_campaigns}
        />
      )
    },
  ]

  const columns_offer = [
    {
      field: '_copy',
      align: 'center',
      headerAlign: 'center',
      headerName: '',
      flex: 0.01,
      sortable: false,
      renderCell: (params) => (
        <button
          onClick={() => setOfferCreateData(params.row)}
          style={{
            width: '20px',
            height: '20px',
            padding: 0,
            border: 'none',
            background: 'transparent',
            cursor: 'pointer',
          }}
        >
          <FontAwesomeIcon icon={faCopy} size="lg" style={{color: "#ffffff",}}/>
        </button>
      ),
    },
    {
      field: "name",
      headerName: "Offer Name",
      flex: 0.4,
      cellClassName: "name-column--cell",
    },
    {
      field: "clicks",
      headerName: "Clicks",
      flex: 0.15,
      ...column_as_number,
    },
    {
      field: "conversions",
      headerName: "Conversions",
      flex: 0.15,
      ...column_as_number,
    },
    {
      field: "url",
      headerName: "Offer Link",
      flex: 0.3,
      renderCell: (params) => {
        try {
          const currentUrl = new URL(params.value);
          return (
            <Tooltip title={params.value} arrow slotProps={{tooltip: {sx: {fontSize: '0.9em'}}}}>
              <div>{currentUrl.hostname}</div>
            </Tooltip>
          )
        } catch (error) {
        }
      },
    },
    {
      field: "keywords",
      headerName: "Keywords",
      flex: 0.8,
    },
    {
      field: "_edit",
      align: 'center',
      headerAlign: 'center',
      headerName: "Edit Offer",
      flex: 0.1,
      sortable: false,
      renderCell: (params) => {
        if (params.row.domain !== '') {
          return (
            <ModalEditOffer
              offer_id={params.row.id}
              get_campaigns={get_campaigns}
            />
          )
        } else {
          return ('')
        }
      }
    },
    {
      field: "_delete",
      align: 'center',
      headerAlign: 'center',
      headerName: "Delete Offer",
      flex: 0.1,
      sortable: false,
      renderCell: (params) => (
        <IconButton aria-label="delete">
          <DeleteIcon onClick={() => {
            setOfferDeleteData({id: params.row.id, title: params.row.name})
            setOfferOpenDelete(true);
          }}/>
        </IconButton>
      ),
    }
  ]

  const columns_source = [
    {
      field: '_empty',
      align: 'center',
      headerAlign: 'center',
      headerName: '',
      flex: 0.01,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Source Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "integrations__type",
      headerName: "Alias",
      flex: 0.2,
      renderCell: (params) => {
        if (params.row.integrations.length > 0) {
          return (
            <div>{params.row.integrations[0].type}</div>
          )
        }
      }
    },
    {
      field: "event",
      headerName: "Event",
      flex: 0.2,
      sortable: false,
      renderCell: (params) => {
        if (params.row.integrations.length > 0 && params.row.integrations[0].conversions_events.length > 0) {
          let event = ''
          let weight = 0
          let conversions_priority = [
            {name: 'Visit', weight: 100},
            {name: 'Term Click', weight: 110},
            {name: 'Click', weight: 200},
            {name: 'Ad View', weight: 210},
            {name: 'Conversion', weight: 300},
            {name: 'Ad Click', weight: 310},
          ]
          params.row.integrations[0].conversions_events.map((conversion_event, index) => {
            let priority_index = conversions_priority.findIndex(function (element) {
              return element.name === conversion_event.conversion.name
            })
            if (priority_index !== -1 && weight < conversions_priority[priority_index].weight) {
              event = conversion_event.event
              weight = conversions_priority[priority_index].weight
            }
          })
          return (
            <div>{event}</div>
          )
        }
      }
    },
    {
      field: "integrations__integration_setting__pixel_id",
      headerName: "Pixel ID",
      flex: 0.5,
      renderCell: (params) => {
        if (params.row.integrations.length > 0) {
          return (
            <div>{params.row.integrations[0].integration_setting.pixel_id}</div>
          )
        }
      }
    },
    {
      field: "_edit",
      align: 'center',
      headerAlign: 'center',
      headerName: "Edit Source",
      flex: 0.15,
      sortable: false,
      renderCell: (params) => (
        <ModalEditSource
          source_id={params.row.id}
          get_campaigns={get_campaigns}
          source_events={source_events}
        />
      )
    },
    {
      field: "_delete",
      align: 'center',
      headerAlign: 'center',
      headerName: "Delete Source",
      flex: 0.15,
      sortable: false,
      renderCell: (params) => (
        <IconButton aria-label="delete">
          <DeleteIcon onClick={() => {
            setSourceDeleteData({id: params.row.id, title: params.row.name})
            setSourceOpenDelete(true);
          }}/>
        </IconButton>
      ),
    },
  ]

  const commonGridProps = {
    density: 'compact',
    hideFooterSelectedRowCount: true,
    sortingMode: 'server',
    pagination: true,
    paginationMode: "server",
    pageSizeOptions: [100],
    disableAggregation: true,
    disableColumnReorder: true,
    disableRowSelectionOnClick: true,
    disableColumnMenu: true,
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title="ClickFlare" subtitle="list of campaigns"/>

      <Box sx={{display: 'flex', alignItems: 'center', margin: '-5px 0 0 0', minHeight: '38px', width: '100%'}}>

        <SearchInput
          storagePath={searchStorage}
          onChange={(value) => {
            setSearchText(value);
          }}
          sx={{maxWidth: '600px', flex: 1}}
        />

        <Grid item xs={3}>
          <ModalNewCampaign
            get_campaigns={get_campaigns}
            offer_templates={offer_templates}
            source_events={source_events}
            data={campaignCreateData} setData={setCampaignCreateData}
          />
        </Grid>

        <Grid item xs={3}>
          <ModalNewOffer
            get_campaigns={get_campaigns}
            offer_templates={offer_templates}
            data={offerCreateData} setData={setOfferCreateData}
          />
        </Grid>

        <Box sx={{flex: 1}}/>
        <Button
          sx={{padding: '7px', minWidth: 0}} variant="contained" onClick={() => get_campaigns()}><RefreshIcon/>
        </Button>
      </Box>

      <ModalCircularProgress load={load}/>

      {modalOfferDeleteWindow(offerOpenDelete)}

      {modalSourceDeleteWindow(sourceOpenDelete)}

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <TabContext value={activeTab}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            sx={{marginTop: '10px'}}
          >
            <Tab value="tab_campaigns" label="Campaigns"/>
            <Tab value="tab_offers" label="Offers"/>
            <Tab value="tab_sources" label="Sources"/>
          </Tabs>

          <TabPanel value="tab_campaigns"
                    sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px'}}>
            <DataGridPremium
              {...commonGridProps}
              initialState={{
                sorting: {
                  sortModel: sortModelCampaigns,
                },
                pagination: {
                  paginationModel: paginationModelCampaigns,
                },
              }}
              rows={campaigns}
              columns={columns_campaign}
              getRowId={(row) => row.id}
              rowCount={rowCountCampaigns}
              onSortModelChange={(newModel) => {
                setSortModelCampaigns(newModel);
              }}
              paginationModel={paginationModelCampaigns}
              pageSizeOptions={[100]}
              onPaginationModelChange={(newModel) => {
                setPaginationModelCampaigns(newModel);
              }}
            />
          </TabPanel>

          <TabPanel value="tab_offers"
                    sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px'}}>
            <DataGridPremium
              {...commonGridProps}
              initialState={{
                sorting: {
                  sortModel: sortModelOffers,
                },
                pagination: {
                  paginationModel: paginationModelOffers,
                },
              }}
              rows={offers}
              columns={columns_offer}
              getRowId={(row) => row.id}
              rowCount={rowCountOffers}
              onSortModelChange={(newModel) => {
                setSortModelOffers(newModel);
              }}
              paginationModel={paginationModelOffers}
              pageSizeOptions={[100]}
              onPaginationModelChange={(newModel) => {
                setPaginationModelOffers(newModel);
              }}
            />
          </TabPanel>

          <TabPanel value="tab_sources"
                    sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px'}}>
            <DataGridPremium
              {...commonGridProps}
              initialState={{
                sorting: {
                  sortModel: sortModelSources,
                },
                pagination: {
                  paginationModel: paginationModelSources,
                },
              }}
              rows={sources}
              columns={columns_source}
              getRowId={(row) => row.id}
              rowCount={rowCountSources}
              onSortModelChange={(newModel) => {
                setSortModelSources(newModel);
              }}
              paginationModel={paginationModelSources}
              pageSizeOptions={[100]}
              onPaginationModelChange={(newModel) => {
                setPaginationModelSources(newModel);
              }}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(ClickFlareMain);
