import {useState} from "react";
import axios from "axios";
import {Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, TextField} from "@mui/material";
import {toast} from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import Header from "../../components/Header";

import {getModalCommonStyle, requestConfig} from "../utils";

const ModalKeywords = (props) => {
  const [openCreate, setOpenCreate] = useState(false);
  const [keywords, setKeywords] = useState([])
  const [kwamount, setKwamount] = useState(6)
  const [campaign, setCampaign] = useState('')
  const amount = [3, 4, 5, 6, 7, 8, 9, 10]

  const get_keywords = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/tonic/keywords/?campaign=${props.id}&campaign_id=${props.campaign_id}`, requestConfig)
      .then(function (response) {
        setCampaign(response.data.at(0).campaign);
        setKwamount(response.data.at(0).kwamount);
        setKeywords(response.data.at(0).keywords);
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  const resize_array = () => {
    if (kwamount !== keywords.length) {
      let current_array = keywords
      let current_length = current_array.length

      while (kwamount !== current_length) {
        if (kwamount > current_length) {
          current_array.push('')
          current_length++
        } else {
          current_array.pop()
          current_length--
        }
      }

      setKeywords(current_array);
    }
  }

  resize_array()

  const keywords_change = (index, event) => {
    const key_array = keywords;
    key_array[index] = event.target.value;
    setKeywords(key_array);
  }

  const openModal = async () => {
    await get_keywords();
    setOpenCreate(true);
  }

  const onClose = () => {
    setOpenCreate(false)
  }

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  const onSubmit = () => {
    const data = {
      campaign_id: props.campaign_id,
      campaign: props.id,
      keywords: keywords.filter(element => element !== ""),
      kwamount: kwamount,
      keyword_amount: kwamount,
      account_name: props.account,
      country: (props.country).match(/\((.*?)\)/)[1]
    }
    axios.post(`${process.env.REACT_APP_API_URL}/api/tonic/keywords/`, data, requestConfig)
      .then(function (response) {
        if (response.status === 201) {
          toast.success('Success!', {position: "top-center", autoClose: 3000});
        } else {
          toast.error(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.statusText)
      })
  }

  const changeAmount = (value) => {
    setKwamount(value);
    resize_array();
  }

  const styleModal = {
    ...getModalCommonStyle(),
    width: 520,
  };

  return (
    <Box>
      <Box m="20px 0 20px 0">
        <Button variant="text" onClick={openModal}>keywords</Button>
      </Box>
      <Modal
        open={openCreate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static"
        onKeyDown={handleKeyDown}
      >
        <Box sx={styleModal}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title="Set keywords" subtitle={`set keywords in ${props.campaign_name}`}/>

            <IconButton onClick={onClose} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>
          </Box>
          <FormControl sx={{width: "450px"}}>
            <InputLabel id="demo-simple-select-label">Keywords Amount</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={kwamount}
              label="Keywords Amount"
              onChange={(event) => changeAmount(event.target.value)}
            >
              {amount.map((num, index) => (
                <MenuItem key={index} value={num}>{num}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {keywords.map((keyword, index) => (
            <Box>
              <TextField key={index} label="Keyword" variant="outlined" sx={{width: 450, marginTop: 2}}
                         defaultValue={keyword}
                         onChange={(event) => keywords_change(index, event)}
              />
            </Box>

          ))}
          <Box display="flex" justifyContent="start" mt="50px">
            <Button color="secondary" variant="contained" onClick={onSubmit}>
              Set keywords
            </Button>
          </Box>

        </Box>

      </Modal>
    </Box>
  )
}

export default ModalKeywords;
