import {useState} from "react";
import axios from "axios";
import {Box, Button, IconButton, Modal, TextField} from "@mui/material";
import {toast} from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import Header from "../../components/Header";
import Autocomplete from "@mui/material/Autocomplete";

import {getModalCommonStyle, requestConfig} from "../utils";

const ModalPixels = (props) => {
  const [openCreate, setOpenCreate] = useState(false);
  const [source, setSource] = useState({source: ['facebook'], event_name: ['Purchase']})

  let defaultPixel = {
    account: props.data.account,
    id: 111100,
    source: "facebook",
    event_name: "empty",
    send_revenue: true,
    revenue_type: 'preestimated_revenue',
    pixel_id: "",
    access_token: "",
    events_s2s_conversion: true,
    domain_verification: 'null',
    deduplication_parameter: "random_id",
    campaign_id: props.data.campaign_id
  }

  const [pixelData, setPixelData] = useState(defaultPixel)
  const setData = (name, value) => {
    setPixelData(prevState => ({
      ...prevState, [name]: value
    }))
    console.log(pixelData)
  }

  const get_pixel_data = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/tonic/pixel/${props.data.pixel_data_id}/`, requestConfig)
      .then(function (response) {
        setPixelData({...response.data})
      })
      .catch(function (error) {
        console.log(error);
      })

    await axios.get(`${process.env.REACT_APP_API_URL}/api/tonic/pixel/source?account=${props.data.account}`, requestConfig)
      .then(function (response) {
        setSource({...response.data})
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  const openModal = async () => {
    await get_pixel_data();
    setOpenCreate(true);
  }

  const closeModal = () => {
    setOpenCreate(false)
  }
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  const onSubmit = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/api/tonic/pixel/`, pixelData, requestConfig)
      .then(function (response) {
        if (response.status === 201) {
          toast.success('Success!', {position: "top-center", autoClose: 3000});
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.statusText)
      })
  }

  const styleModal = {
    ...getModalCommonStyle(),
    width: 620,
  };

  const tiktok_fields = () => {
    return (
      <Box>
        <TextField required id="outlined-basic" label="Pixel" variant="outlined" sx={{width: 550, marginTop: 3}}
                   defaultValue={pixelData.pixel_id}
                   onChange={(event) => setData('pixel_id', event.target.value)}/>
        <TextField required id="outlined-basic" label="Token" variant="outlined" sx={{width: 550, marginTop: 3}}
                   defaultValue={pixelData.access_token}
                   onChange={(event) => setData('access_token', event.target.value)}/>
        {pixelData.send_revenue == true ?
          <TextField disabled id="outlined-basic" label="Revenue type (optional)" variant="outlined"
                     sx={{width: 550, marginTop: 3}}
                     defaultValue={pixelData.revenue_type}
                     onChange={(event) => setData('revenue_type', event.target.value)}/>
          : <Box></Box>
        }
        <Autocomplete
          required={true}
          disabled
          disablePortal
          id="combo-box-demo"
          options={['YES', 'NO']}
          getOptionLabel={(option) => option}
          defaultValue={pixelData.events_s2s_conversion == true ? 'YES' : 'NO'}
          sx={{width: 550, marginTop: 3}}
          onChange={(event, values) => {
            console.log(values)
            setData('events_s2s_conversion', values === 'YES' ? true : false)
          }}
          renderInput={(params) => <TextField {...params} label="Use Tonic tracking by default?"/>}
        />


      </Box>
    )
  }

  const facebook_fields = () => {
    return (
      <Box>
        <TextField required id="outlined-basic" label="Pixel" variant="outlined" sx={{width: 550, marginTop: 3}}
                   defaultValue={pixelData.pixel_id}
                   onChange={(event) => setData('pixel_id', event.target.value)}/>
        <TextField required id="outlined-basic" label="Token" variant="outlined" sx={{width: 550, marginTop: 3}}
                   defaultValue={pixelData.access_token}
                   onChange={(event) => setData('access_token', event.target.value)}/>

        <TextField id="outlined-basic" label="Domain Verification (optional)" variant="outlined"
                   sx={{width: 550, marginTop: 3}}
                   defaultValue={pixelData.domain_verification}
                   onChange={(event) => setData('domain_verification', event.target.value)}/>
        <TextField disabled id="outlined-basic" label="Deduplication Parameter (optional)" variant="outlined"
                   sx={{width: 550, marginTop: 3}}
                   defaultValue={pixelData.deduplication_parameter}
                   onChange={(event) => setData('deduplication_parameter', event.target.value)}/>
      </Box>
    )
  }

  const taboola_fields = () => {
    return (
      <Box>

      </Box>
    )
  }

  const pixelJsx = () => {
    if (pixelData.source === 'tiktok') {
      return tiktok_fields()
    } else if (pixelData.source === 'facebook') {
      return facebook_fields()
    } else {
      <Box>EMPTY</Box>
    }
  }

  return (
    <Box>
      <Box m="20px 0 20px 0">
        <Button variant="text" onClick={openModal}>pixel</Button>
      </Box>
      <Modal
        open={openCreate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static"
        onKeyDown={handleKeyDown}
      >
        <Box sx={styleModal}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title='Set pixel data' subtitle={props.data.name}/>

            <IconButton onClick={closeModal} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>
          </Box>
          <Autocomplete
            required={true}
            disablePortal
            id="combo-box-demo"
            options={source.source}
            getOptionLabel={(option) => option}
            defaultValue={pixelData.source}
            sx={{width: 300, marginTop: 3}}
            onChange={(event, values) => setData('source', values)}
            renderInput={(params) => <TextField {...params} label="Choose source"/>}
          />

          <Autocomplete
            required={true}
            disablePortal
            id="combo-box-demo"
            options={source.event_name}
            getOptionLabel={(option) => option}
            defaultValue={pixelData.event_name}
            sx={{width: 300, marginTop: 3}}
            onChange={(event, values) => setData('event_name', values)}
            renderInput={(params) => <TextField {...params} label="Choose Event"/>}
          />

          <Autocomplete
            required={true}
            disablePortal
            id="combo-box-demo"
            options={['YES', 'NO']}
            getOptionLabel={(option) => option}
            defaultValue={pixelData.send_revenue == true ? 'YES' : 'NO'}
            sx={{width: 300, marginTop: 3}}
            onChange={(event, values) => {
              console.log(values)
              setData('send_revenue', values === 'YES' ? true : false)
            }}
            renderInput={(params) => <TextField {...params} label="Send Revenue?"/>}
          />

          {pixelJsx()}

          <Box display="flex" justifyContent="start" mt="50px">
            <Button color="secondary" variant="contained" onClick={onSubmit}>
              Set Pixel
            </Button>
          </Box>

        </Box>

      </Modal>
    </Box>
  )
}

export default ModalPixels;
