import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {connect, useSelector} from 'react-redux';
import {Box, Button, Grid, IconButton, Modal} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import {toast} from "react-toastify";
import Header from "../../components/Header";
import ModalNewLink from "./ModalNewLink";
import ModalNewOffer from "./ModalNewOffer";
import ModalOffer from "./ModalOffer";
import ModalSource from "./ModalSource";
import ModalCampaignEdit from "./ModalCampaignEdit";
import PixelActivate from "./PixelActivate";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy} from '@fortawesome/free-solid-svg-icons';
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import {SearchInput, searchLoadText} from "../../search";
import {
  column_as_number,
  convertSortModelToDjangoSort,
  getDataGridCommonStyle,
  ModalCircularProgress,
  requestConfig
} from "../utils";
import {DataGridPremium} from "@mui/x-data-grid-premium";


const RedtrackMain = ({isAuthenticated}) => {
  const navigate = useNavigate();
  const searchStorage = RedtrackMain.name
  const [load, setLoad] = useState(false)

  const [campaigns, setCampaigns] = useState([{id: 1}]);
  const [offers, setOffers] = useState([{id: 1}]);
  const [sources, setSources] = useState([{id: 1}]);
  const [allDomains, setAllDomains] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [allOffers, setAllOffers] = useState([]);
  const [allSources, setAllSources] = useState([]);

  const [campaignId, setCampaignId] = useState("");
  const [campaignTitle, setCampaignTitle] = useState("");
  const [campaignAdText, setCampaignAdText] = useState('');
  const [campaignUrl, setCampaignUrl] = useState('');
  const [campaignSourcePixel, setCampaignSourcePixel] = useState('');
  const [campaignSourceToken, setCampaignSourceToken] = useState('');
  const [campaignSourceEvent, setCampaignSourceEvent] = useState('');
  const [campaignOfferKeyWords, setCampaignOfferKeyWords] = useState('');
  const [campaignOfferTemplate, setCampaignOfferTemplate] = useState('');
  const [campaignDomainId, setCampaignDomainId] = useState('');
  const [campaignPrimaryKey, setCampaignPrimaryKey] = useState('')
  const [campaignOpenCreate, setCampaignOpenCreate] = useState(false);

  const [offerCampaignId, setOfferCampaignId] = useState("");
  const [offerTitle, setOfferTitle] = useState("");
  const [offerAdText, setOfferAdText] = useState("");
  const [offerUrl, setOfferUrl] = useState("");
  const [offerKeyWords, setOfferKeyWords] = useState("");
  const [offerTemplate, setOfferTemplate] = useState("");
  const [offerOpenCreate, setOfferOpenCreate] = useState(false);
  const [offerDeleteData, setOfferDeleteData] = useState({id: '', title: ''})
  const [offerOpenDelete, setOfferOpenDelete] = useState(false)

  const [sourceDeleteData, setSourceDeleteData] = useState({id: '', title: ''})
  const [sourceOpenDelete, setSourceOpenDelete] = useState(false)

  const [activeTab, setActiveTab] = useState('tab_campaigns');
  const [sortModelCampaigns, setSortModelCampaigns] = useState([]);
  const [sortModelOffers, setSortModelOffers] = useState([]);
  const [sortModelSources, setSortModelSources] = useState([]);
  const [rowCountCampaigns, setRowCountCampaigns] = useState(0)
  const [rowCountOffers, setRowCountOffers] = useState(0)
  const [rowCountSources, setRowCountSources] = useState(0)
  const [paginationModelCampaigns, setPaginationModelCampaigns] = useState({pageSize: 100, page: 0})
  const [paginationModelOffers, setPaginationModelOffers] = useState({pageSize: 100, page: 0})
  const [paginationModelSources, setPaginationModelSources] = useState({pageSize: 100, page: 0})
  const [searchText, setSearchText] = useState(() => {
    return searchLoadText(searchStorage);
  });

  const startStr = useSelector(state => state.date.startDateStr);
  const endStr = useSelector(state => state.date.endDateStr);
  const selectedEmployee = useSelector(state => state.employee.selectedEmployee);

  const templateBackendToDisplayMapping = {
    'cross-facebook': 'Cross - Facebook',
    'sedo-facebook': 'Sedo - Facebook',
    'cross-tiktok': 'Cross - TikTok',
    'tonic-tiktok': 'Tonic - TikTok',
    'codefuel-facebook': 'Yahoo - Facebook',
    'codefuel-tiktok': 'Yahoo - TikTok',
    'ads-facebook': 'Ads - Facebook',
    'ads-tiktok': 'Ads - TikTok',
    'tonicTrackTonic-facebook': 'Tonic 1 - Facebook',
    'tonicCodefuel-facebook': 'Tonic 2 - Facebook',
  };


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setPaginationModelCampaigns(prev => ({...prev, page: 0}));
    setPaginationModelOffers(prev => ({...prev, page: 0}));
    setPaginationModelSources(prev => ({...prev, page: 0}));
  }, [startStr, endStr, selectedEmployee, searchText]);

  useEffect(() => {
    get_campaigns();
  }, [activeTab, paginationModelCampaigns, paginationModelOffers, paginationModelSources, sortModelCampaigns, sortModelOffers, sortModelSources]);


  const get_campaigns = () => {
    if (activeTab === 'tab_campaigns') {
      const ordering = convertSortModelToDjangoSort(sortModelCampaigns).join(',');

      setLoad(true);
      axios.get(`${process.env.REACT_APP_API_URL}/api/redtrack/campaigns/?page=${paginationModelCampaigns.page + 1}&page_size=${paginationModelCampaigns.pageSize}&start_date=${startStr}&end_date=${endStr}&search=${searchText}&ordering=${ordering}`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            const {results, count} = response.data;
            setCampaigns(results)
            setRowCountCampaigns(count)
          } else {
            console.log(response.statusText)
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setLoad(false);
        })
    }

    if (activeTab === 'tab_offers') {
      const ordering = convertSortModelToDjangoSort(sortModelOffers).join(',');

      setLoad(true);
      axios.get(`${process.env.REACT_APP_API_URL}/api/redtrack/offers/?page=${paginationModelOffers.page + 1}&page_size=${paginationModelOffers.pageSize}&search=${searchText}&ordering=${ordering}`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            const {results, count} = response.data;
            setOffers(results)
            setRowCountOffers(count)
          } else {
            console.log(response.statusText)
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setLoad(false);
        })
    }

    if (activeTab === 'tab_sources') {
      const ordering = convertSortModelToDjangoSort(sortModelSources).join(',');

      setLoad(true);
      axios.get(`${process.env.REACT_APP_API_URL}/api/redtrack/sources/?page=${paginationModelSources.page + 1}&page_size=${paginationModelSources.pageSize}&search=${searchText}&ordering=${ordering}`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            const {results, count} = response.data;
            setSources(results)
            setRowCountSources(count)
          } else {
            console.log(response.statusText)
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setLoad(false);
        })
    }
  };

  const get_references = async (reference_name) => {
    if (reference_name === 'domains') {
      await axios.get(`${process.env.REACT_APP_API_URL}/api/redtrack/domains/`, requestConfig)
        .then(function (response) {
          setAllDomains(response.data)
        })
        .catch(function (error) {
          console.log(error);
        })
    }

    if (reference_name === 'campaigns') {
      await axios.get(`${process.env.REACT_APP_API_URL}/api/redtrack/campaigns/reference/`, requestConfig)
        .then(function (response) {
          setAllCampaigns(response.data)
        })
        .catch(function (error) {
          console.log(error);
        })
    }

    if (reference_name === 'offers') {
      await axios.get(`${process.env.REACT_APP_API_URL}/api/redtrack/offers/reference/`, requestConfig)
        .then(function (response) {
          setAllOffers(response.data)
        })
        .catch(function (error) {
          console.log(error);
        })
    }

    if (reference_name === 'sources') {
      await axios.get(`${process.env.REACT_APP_API_URL}/api/redtrack/sources/reference/`, requestConfig)
        .then(function (response) {
          setAllSources(response.data)
        })
        .catch(function (error) {
          console.log(error);
        })
    }
  }

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleCampaignOfferLinkClick = (text) => {
    // navigator.clipboard.writeText(params);
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
    toast.success("Link copied", {position: "top-center", autoClose: 2000});
  };

  const handleTabChange = (event, newValue) => {
    if (activeTab === newValue) {
      return;
    }

    setActiveTab(newValue);

    if (activeTab !== 'tab_campaigns') {
      setCampaigns([])
    }
    if (activeTab !== 'tab_offers') {
      setOffers([])
    }
    if (activeTab !== 'tab_sourcess') {
      setSources([])
    }
  };

  const handleOfferCloseDelete = () => {
    setOfferOpenDelete(false)
  }

  const handleOfferDeleteRequest = async () => {
    setLoad(true)
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/redtrack/offers/${offerDeleteData.id}/`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          console.log(response.data)
        } else {
          console.log(response.statusText)
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setOfferOpenDelete(false)
        get_campaigns()
        setLoad(false)
      })
  }

  const modalOfferDeleteWindow = (status) => {
    return (
      <Modal
        open={status}
        onClose={handleOfferCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <IconButton onClick={handleOfferCloseDelete} sx={{position: "absolute", top: 15, right: 15,}}>
            <CloseIcon/>
          </IconButton>
          <Header title="Delete Offer?" subtitle={offerDeleteData.title}/>

          <div>
            {offerDeleteData.offer_campaigns && offerDeleteData.offer_campaigns.length > 0 && (
              <>
                <p>Offer is being used in splits for campaigns:</p>
                <ul>
                  {offerDeleteData.offer_campaigns.map((campaign, index) => (
                    <li key={index}>{campaign.campaign_title}</li>
                  ))}
                </ul>
                <br/>
              </>
            )}
          </div>

          <Grid container sx={{display: 'flex', justifyContent: 'flex-end', gap: '20px'}}>
            <Grid item>
              <Button variant="contained" color="success" onClick={handleOfferDeleteRequest}>Yes</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="error" onClick={handleOfferCloseDelete}>No</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    )
  }

  const handleSourceCloseDelete = () => {
    setSourceOpenDelete(false)
  }

  const handleSourceDeleteRequest = async () => {
    setLoad(true)
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/redtrack/sources/${sourceDeleteData.id}/`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          console.log(response.data)
        } else {
          console.log(response.statusText)
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setSourceOpenDelete(false)
        get_campaigns()
        setLoad(false)
      })
  }

  const modalSourceDeleteWindow = (status) => {
    return (
      <Modal
        open={status}
        onClose={handleSourceCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <IconButton onClick={handleSourceCloseDelete} sx={{position: "absolute", top: 15, right: 15,}}>
            <CloseIcon/>
          </IconButton>
          <Header title="Delete Source?" subtitle={sourceDeleteData.title}/>
          <Grid container sx={{display: 'flex', justifyContent: 'flex-end', gap: '20px'}}>
            <Grid>
              <Button variant="contained" color="success" onClick={handleSourceDeleteRequest}>Yes</Button>
            </Grid>
            <Grid>
              <Button variant="contained" color="error" onClick={handleSourceCloseDelete}>No</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    )
  }

  const handleCampaignCopyClick = async (row, navigate) => {
    let link_offer;
    try {
      const currentUrl = new URL(row.link_offer);
      link_offer = currentUrl.origin + currentUrl.pathname;
    } catch (error) {
      link_offer = row.link_offer;
    }

    await get_references('domains')
    setCampaignTitle(row.title)
    setCampaignAdText(row.ad_text)
    setCampaignUrl(link_offer)
    setCampaignSourcePixel(row.pixel)
    setCampaignSourceToken(row.token)
    setCampaignOfferKeyWords(row.keywords)
    setCampaignSourceEvent(row.event)
    setCampaignDomainId(row.id_domain)
    const displayTemplate = templateBackendToDisplayMapping[row.offer_template]
    setCampaignOfferTemplate(displayTemplate)
    setCampaignPrimaryKey(row.id)
    setCampaignOpenCreate(true);
  }

  const handleOfferCopyClick = async (row, navigate) => {
    let link_offer;
    try {
      const currentUrl = new URL(row.link_offer);
      link_offer = currentUrl.origin + currentUrl.pathname;
    } catch (error) {
      link_offer = row.link_offer;
    }

    await get_references('campaigns')
    setOfferCampaignId("")
    setOfferTitle(row.title)
    setOfferAdText("")
    setOfferUrl(link_offer)
    setOfferKeyWords(row.keywords)
    const displayTemplate = templateBackendToDisplayMapping[row.template]
    setOfferTemplate(displayTemplate)
    setOfferOpenCreate(true);
  }

  const columns_campaign = [
    {
      field: '_copy',
      align: 'center',
      headerAlign: 'center',
      headerName: '',
      flex: 0.01,
      sortable: false,
      renderCell: (params) => (
        <button
          onClick={() => handleCampaignCopyClick(params.row, navigate)}
          style={{
            width: '20px',
            height: '20px',
            padding: 0,
            border: 'none',
            background: 'transparent',
            cursor: 'pointer',
          }}
        >
          <FontAwesomeIcon icon={faCopy} size="lg" style={{color: "#ffffff",}}/>
        </button>
      ),
    },
    {
      field: "created",
      align: 'center',
      headerAlign: 'center',
      headerName: "Created",
      flex: 0.1,
    },
    {
      field: "title",
      headerName: "Campaign Name",
      flex: 0.5,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Link to={`/redtrack/statistic/${params.row.id}`}>{params.value}</Link>
        )
      }
    },
    {
      field: "clicks",
      headerName: "Clicks",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "conversions",
      headerName: "Conversions",
      flex: 0.1,
      ...column_as_number,
    },
    {
      field: "traker_link",
      headerName: "Tracker Link",
      flex: 0.5,
      renderCell: (params) => (
        <div className="offerlink" onClick={() => {
          handleCampaignOfferLinkClick(params.value)
        }}>{params.value}</div>
      )
    },
    {
      field: "splits",
      headerName: "Splits",
      flex: 0.075,
      ...column_as_number,
    },
    {
      field: "_edit",
      align: 'center',
      headerAlign: 'center',
      headerName: "Edit Campaign",
      flex: 0.1,
      sortable: false,
      renderCell: (params) => (
        <ModalCampaignEdit
          campaign_id={params.row.id}
          domains={allDomains}
          offers={allOffers}
          sources={allSources}
          get_campaigns={get_campaigns}
          get_references={get_references}
        />
      )
    },
  ]

  const columns_offer = [
    {
      field: '_copy',
      align: 'center',
      headerAlign: 'center',
      headerName: '',
      flex: 0.01,
      sortable: false,
      renderCell: (params) => (
        <button
          onClick={() => handleOfferCopyClick(params.row, navigate)}
          style={{
            width: '20px',
            height: '20px',
            padding: 0,
            border: 'none',
            background: 'transparent',
            cursor: 'pointer',
          }}
        >
          <FontAwesomeIcon icon={faCopy} size="lg" style={{color: "#ffffff",}}/>
        </button>
      ),
    },
    {
      field: "title",
      headerName: "Offer Name",
      flex: 0.4,
      cellClassName: "name-column--cell",
    },
    {
      field: "clicks",
      headerName: "Clicks",
      flex: 0.15,
      ...column_as_number,
    },
    {
      field: "conversions",
      headerName: "Conversions",
      flex: 0.15,
      ...column_as_number,
    },
    {
      field: "link_offer",
      headerName: "Offer Link",
      flex: 0.3,
      renderCell: (params) => {
        try {
          const currentUrl = new URL(params.value);
          return (
            <Tooltip title={params.value} arrow slotProps={{tooltip: {sx: {fontSize: '0.9em'}}}}>
              <div>{currentUrl.hostname}</div>
            </Tooltip>
          )
        } catch (error) {
        }
      },
    },
    {
      field: "keywords",
      headerName: "Keywords",
      flex: 0.8,
    },
    {
      field: '_edit',
      align: 'center',
      headerAlign: 'center',
      headerName: "Edit Offer",
      flex: 0.1,
      sortable: false,
      renderCell: (params) => {
        let offer_data = {};
        offers.map((camp) => {
          if (camp.id === params.row.id) {
            offer_data = camp;
          }
        })
        return (
          <ModalOffer offer_data={offer_data} get_campaigns={get_campaigns}/>
        )
      }
    },
    {
      field: '_delete',
      align: 'center',
      headerAlign: 'center',
      headerName: "Delete Offer",
      flex: 0.15,
      sortable: false,
      renderCell: (params) => (
        <IconButton aria-label="delete">
          <DeleteIcon onClick={() => {
            let offer_campaigns = [];

            axios.get(`${process.env.REACT_APP_API_URL}/api/redtrack/offers/${params.row.id}/campaigns/`, requestConfig)
              .then(function (response) {
                if (response.status === 200) {
                  offer_campaigns = response.data;
                } else {
                  toast.error(response.data)
                }
              })
              .catch(function (error) {
                console.log(error);
              })
              .finally(function () {
                setOfferDeleteData({id: params.row.id, title: params.row.title, offer_campaigns: offer_campaigns})
                setOfferOpenDelete(true);
              })
          }}/>
        </IconButton>
      ),
    }
  ]

  const columns_source = [
    {
      field: '_empty',
      align: 'center',
      headerAlign: 'center',
      headerName: '',
      flex: 0.01,
      sortable: false,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "alias",
      headerName: "Alias",
      flex: 0.2,
    },
    {
      field: "event",
      headerName: "Event",
      flex: 0.2,
    },
    {
      field: "pixel",
      headerName: "Pixel ID",
      flex: 0.5,
    },
    {
      field: "token",
      headerName: "Token",
      flex: 0.5,
    },
    {
      field: "is_active",
      align: 'center',
      headerAlign: 'center',
      headerName: "Pixel Status",
      flex: 0.25,
      sortable: false,
      renderCell: (params) => {
        let pixel = '';
        let alias = '';
        let token = '';
        let source_name = '';
        let event_name = '';
        let is_active = '';
        sources.map((camp, index) => {
          if (camp.id === params.id) {
            pixel = camp.pixel;
            token = camp.token;
            source_name = camp.title;
            event_name = camp.event
            alias = camp.alias
            is_active = camp.is_active
          }
        })
        return (
          <PixelActivate pixel={pixel} token={token} alias={alias} is_active={is_active}
                         get_campaigns={get_campaigns}/>
        )
      }
    },
    {
      field: "_edit",
      align: 'center',
      headerAlign: 'center',
      headerName: "Edit Source",
      flex: 0.2,
      sortable: false,
      renderCell: (params) => {
        let pixel = '';
        let token = '';
        let source_name = '';
        let event_name = '';
        sources.map((camp, index) => {
          if (camp.id === params.row.id) {
            pixel = camp.pixel;
            token = camp.token;
            source_name = camp.title;
            event_name = camp.event
          }
        })
        return (
          <ModalSource source_id={params.row.id} pixel={pixel} token={token} source_name={source_name}
                       event_name={event_name} get_campaigns={get_campaigns}/>
        )
      }
    },
    {
      field: '_delete',
      align: 'center',
      headerAlign: 'center',
      headerName: "Delete Source",
      flex: 0.2,
      sortable: false,
      renderCell: (params) => (
        <IconButton aria-label="delete">
          <DeleteIcon onClick={() => {
            setSourceDeleteData({id: params.row.id, title: params.row.title})
            setSourceOpenDelete(true);
          }}/>
        </IconButton>
      ),
    },
  ]

  const commonGridProps = {
    density: 'compact',
    hideFooterSelectedRowCount: true,
    sortingMode: 'server',
    pagination: true,
    paginationMode: "server",
    pageSizeOptions: [100],
    disableColumnReorder: true,
    disableRowSelectionOnClick: true,
    disableColumnMenu: true,
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 16px)'}}>
      <Header title="RedTrack" subtitle="list of campaigns"/>

      <Box sx={{display: 'flex', alignItems: 'center', margin: '-5px 0 0 0', minHeight: '38px', width: '100%'}}>

        <SearchInput
          storagePath={searchStorage}
          onChange={(value) => {
            setSearchText(value)
          }}
          sx={{maxWidth: '600px', flex: 1}}
        />

        <Grid item xs={3}>
          <ModalNewLink
            domains={allDomains}
            get_campaigns={get_campaigns} get_references={get_references}
            campaignOpenCreate={campaignOpenCreate} setCampaignOpenCreate={setCampaignOpenCreate}
            campaignId={campaignId} setCampaignId={setCampaignId}
            campaignTitle={campaignTitle} setCampaignTitle={setCampaignTitle}
            campaignAdText={campaignAdText} setCampaignAdText={setCampaignAdText}
            campaignUrl={campaignUrl} setCampaignUrl={setCampaignUrl}
            campaignSourcePixel={campaignSourcePixel} setCampaignSourcePixel={setCampaignSourcePixel}
            campaignSourceToken={campaignSourceToken} setCampaignSourceToken={setCampaignSourceToken}
            campaignOfferKeyWords={campaignOfferKeyWords} setCampaignOfferKeyWords={setCampaignOfferKeyWords}
            campaignSourceEvent={campaignSourceEvent} setCampaignSourceEvent={setCampaignSourceEvent}
            campaignDomainId={campaignDomainId} setCampaignDomainId={setCampaignDomainId}
            campaignOfferTemplate={campaignOfferTemplate} setCampaignOfferTemplate={setCampaignOfferTemplate}
            campaignPrimaryKey={campaignPrimaryKey} setCampaignPrimaryKey={setCampaignPrimaryKey}
          />
        </Grid>

        <Grid item xs={3}>
          <ModalNewOffer
            campaigns={allCampaigns}
            get_campaigns={get_campaigns} get_references={get_references}
            offerOpenCreate={offerOpenCreate} setOfferOpenCreate={setOfferOpenCreate}
            offerCampaignId={offerCampaignId} setOfferCampaignId={setOfferCampaignId}
            offerTitle={offerTitle} setOfferTitle={setOfferTitle}
            offerAdText={offerAdText} setOfferAdText={setOfferAdText}
            offerUrl={offerUrl} setOfferUrl={setOfferUrl}
            offerKeyWords={offerKeyWords} setOfferKeyWords={setOfferKeyWords}
            offerTemplate={offerTemplate} setOfferTemplate={setOfferTemplate}
          />
        </Grid>

        <Box sx={{flex: 1}}/>
        <Button
          sx={{padding: '7px', minWidth: 0}} variant="contained" onClick={() => get_campaigns()}><RefreshIcon/>
        </Button>
      </Box>

      <ModalCircularProgress load={load}/>

      {modalOfferDeleteWindow(offerOpenDelete)}

      {modalSourceDeleteWindow(sourceOpenDelete)}

      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', ...getDataGridCommonStyle()}}>
        <TabContext value={activeTab}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            sx={{marginTop: '10px'}}
          >
            <Tab value="tab_campaigns" label="Campaigns"/>
            <Tab value="tab_offers" label="Offers"/>
            <Tab value="tab_sources" label="Sources"/>
          </Tabs>

          <TabPanel value="tab_campaigns"
                    sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px'}}>
            <DataGridPremium
              {...commonGridProps}
              initialState={{
                sorting: {
                  sortModel: sortModelCampaigns,
                },
                pagination: {
                  paginationModel: paginationModelCampaigns,
                },
              }}
              rows={campaigns}
              columns={columns_campaign}
              rowCount={rowCountCampaigns}
              onSortModelChange={(newModel) => {
                setSortModelCampaigns(newModel);
              }}
              paginationModel={paginationModelCampaigns}
              pageSizeOptions={[100]}
              onPaginationModelChange={(newModel) => {
                setPaginationModelCampaigns(newModel);
              }}
            />
          </TabPanel>

          <TabPanel value="tab_offers"
                    sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px'}}>
            <DataGridPremium
              {...commonGridProps}
              initialState={{
                sorting: {
                  sortModel: sortModelOffers,
                },
                pagination: {
                  paginationModel: paginationModelOffers,
                },
              }}
              rows={offers}
              columns={columns_offer}
              rowCount={rowCountOffers}
              onSortModelChange={(newModel) => {
                setSortModelOffers(newModel);
              }}
              paginationModel={paginationModelOffers}
              pageSizeOptions={[100]}
              onPaginationModelChange={(newModel) => {
                setPaginationModelOffers(newModel);
              }}
            />
          </TabPanel>

          <TabPanel value="tab_sources"
                    sx={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', marginTop: '10px'}}>
            <DataGridPremium
              {...commonGridProps}
              initialState={{
                sorting: {
                  sortModel: sortModelSources,
                },
                pagination: {
                  paginationModel: paginationModelSources,
                },
              }}
              rows={sources}
              columns={columns_source}
              rowCount={rowCountSources}
              onSortModelChange={(newModel) => {
                setSortModelSources(newModel);
              }}
              paginationModel={paginationModelSources}
              pageSizeOptions={[100]}
              onPaginationModelChange={(newModel) => {
                setPaginationModelSources(newModel);
              }}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(RedtrackMain);
