import {format} from 'date-fns';

const defaultDate = {
  startDate: new Date(),
  endDate: new Date(),
  startDateStr: format(new Date(), 'yyyy-MM-dd'),
  endDateStr: format(new Date(), 'yyyy-MM-dd'),
};

export default function (state = defaultDate, action) {
  switch (action.type) {
    case 'SET_START':
      return {
        ...state, startDate: action.payload,
        startDateStr: format(action.payload, 'yyyy-MM-dd')
      }
    case 'SET_END':
      return {
        ...state, endDate: action.payload,
        endDateStr: format(action.payload, 'yyyy-MM-dd')
      }

    default :
      return state;
  }
}
