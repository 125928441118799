import axios from "axios";
import {Box, Button} from "@mui/material";
import {toast} from "react-toastify";

import {requestConfig} from "../utils";


const InvokePixels = (props) => {
  const send_request = () => {
    axios.get(
      `${process.env.REACT_APP_API_URL}/api/tonic/pixel/invoke?campaign_id=${props.campaign_id}&account=${props.account}`, requestConfig)
      .then(function (response) {
        console.log(response.data)
        toast.success('Pixel invoked successfully!', {position: "top-center", autoClose: 3000});
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.data)
      })
  }

  return (
    <Box>
      <Box m="20px 0 20px 0">
        <Button variant="text" onClick={send_request}>Activate</Button>
      </Box>

    </Box>
  )
}

export default InvokePixels;
