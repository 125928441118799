import {useState} from "react";
import axios from "axios";
import {Box, Button, IconButton, Modal, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from '@mui/icons-material/Close';

import {toast} from "react-toastify";
import Header from "../../components/Header";
import {formatErrorMessage, getModalCommonStyle, requestConfig} from "../utils";

const ModalNewLink = (props) => {
  const [isButtonCreateDisabled, setButtonCreateDisabled] = useState(false);

  const templateDisplayToBackendMapping = {
    'Cross - Facebook': 'cross-facebook',
    'Sedo - Facebook': 'sedo-facebook',
    'Cross - TikTok': 'cross-tiktok',
    'Tonic - TikTok': 'tonic-tiktok',
    // 'Yahoo - Facebook': 'codefuel-facebook',
    // 'Yahoo - TikTok': 'codefuel-tiktok',
    'Ads - Facebook': 'ads-facebook',
    'Ads - TikTok': 'ads-tiktok',
    'Tonic 1 - Facebook': 'tonicTrackTonic-facebook',
    'Tonic 2 - Facebook': 'tonicCodefuel-facebook'
  };
  const choose_template = Object.keys(templateDisplayToBackendMapping);
  const choose_event = ['Purchase', 'ViewContent', 'Lead', 'Search']

  const styleModal = {
    ...getModalCommonStyle(),
    width: 470,
  };

  const onModalClose = () => {
    props.setCampaignOpenCreate(false)
  }

  const onModalKeyDown = (event) => {
    if (event.key === "Escape") {
      onModalClose();
    }
  };

  const handleCampaignCreateClick = async () => {
    await props.get_references('domains')
    props.setCampaignTitle("")
    props.setCampaignAdText("")
    props.setCampaignUrl("")
    props.setCampaignSourcePixel("")
    props.setCampaignSourceToken("")
    props.setCampaignOfferKeyWords("")
    props.setCampaignSourceEvent("")
    props.setCampaignDomainId("")
    props.setCampaignOfferTemplate("")
    props.setCampaignOpenCreate(true)
  };

  const handleCampaignCreateSubmit = async () => {
    const data = {
      id: props.campaignPrimaryKey,
      title: props.campaignTitle,
      offer: props.campaignAdText,
      url: props.campaignUrl,
      pixel_id: props.campaignSourcePixel,
      conversions_apikey: props.campaignSourceToken,
      template: templateDisplayToBackendMapping[props.campaignOfferTemplate] || props.campaignOfferTemplate,
      kw: props.campaignOfferKeyWords,
      domain_id: props.campaignDomainId,
      event: props.campaignSourceEvent,
    }

    setButtonCreateDisabled(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/redtrack/campaigns/`, data, requestConfig)
        .then(function (response) {
          if (response.status === 201) {
            toast.success('Success! Tracker link will be created in 5-20 seconds', {
              position: "top-center",
              autoClose: 3000
            });
            props.setCampaignOpenCreate(false);
          } else {
            toast.error(response.data)
          }
          props.get_campaigns();
        })
        .catch(function (error) {
          console.log(error);
          toast.error(formatErrorMessage(error));
        })
    } finally {
      setButtonCreateDisabled(false);
    }
  };

  return (
    <Box>
      <Box m="0 0 0 20px">
        <Button variant="contained" onClick={handleCampaignCreateClick}>Create new Tracker Link</Button>
      </Box>

      <Modal
        open={props.campaignOpenCreate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static"
        onKeyDown={onModalKeyDown}
      >
        <Box sx={styleModal}>

          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: 2}}>
            <Header title="Create link" subtitle="create new tracker link"/>

            <IconButton onClick={onModalClose} sx={{position: "absolute", top: 15, right: 15,}}>
              <CloseIcon/>
            </IconButton>
          </Box>

          <TextField required id="outlined-basic" label="Title" variant="outlined" sx={{width: 400, marginTop: 1}}
                     onChange={(event) => props.setCampaignTitle(event.target.value)} value={props.campaignTitle}/>

          <TextField required id="outlined-basic" label="Ad text" variant="outlined" sx={{width: 400, marginTop: 2}}
                     onChange={(event) => props.setCampaignAdText(event.target.value)} value={props.campaignAdText}/>

          <TextField required id="outlined-basic" label="Offer's url" variant="outlined"
                     sx={{width: 400, marginTop: 2}}
                     onChange={(event) => props.setCampaignUrl(event.target.value)} value={props.campaignUrl}/>

          <TextField id="outlined-basic" label="Pixel ID" variant="outlined" sx={{width: 400, marginTop: 2}}
                     onChange={(event) => props.setCampaignSourcePixel(event.target.value)}
                     value={props.campaignSourcePixel}/>

          <TextField id="outlined-basic" label="Token" variant="outlined" sx={{width: 400, marginTop: 2}}
                     onChange={(event) => props.setCampaignSourceToken(event.target.value)}
                     value={props.campaignSourceToken}/>

          <Autocomplete
            required={true}
            disablePortal
            id="combo-box-demo"
            options={choose_event}
            getOptionLabel={(option) => option}
            sx={{width: 400, marginTop: 2}}
            onChange={(event, values) => props.setCampaignSourceEvent(values)}
            renderInput={(params) => <TextField {...params} label="Choose event name"/>}
            value={props.campaignSourceEvent}
          />

          <TextField id="outlined-basic" label="Keywords separated by comma" variant="outlined"
                     sx={{width: 400, marginTop: 2}}
                     onChange={(event) => props.setCampaignOfferKeyWords(event.target.value)}
                     value={props.campaignOfferKeyWords}/>

          <Autocomplete
            required={true}
            disablePortal
            id="combo-box-demo"
            options={choose_template}
            getOptionLabel={(option) => option}
            sx={{width: 400, marginTop: 2}}
            onChange={(event, values) => props.setCampaignOfferTemplate(values)}
            renderInput={(params) => <TextField {...params} label="Choose template offer link"/>}
            value={props.campaignOfferTemplate}
          />

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={props.domains}
            getOptionLabel={(option) => option.domain_name}
            sx={{width: 400, marginTop: 2}}
            onChange={(event, values) => props.setCampaignDomainId(values.domain_id)}
            renderInput={(params) => <TextField {...params} label="Choose the domain"/>}
            value={props.domains.find(domain => domain.domain_id === props.campaignDomainId)}
          />

          <Box display="flex" justifyContent="start" mt="35px">
            <Button color="secondary" variant="contained" onClick={handleCampaignCreateSubmit}
                    disabled={isButtonCreateDisabled}>
              Create New Tracker Link
            </Button>
          </Box>
        </Box>

      </Modal>
    </Box>
  )
}

export default ModalNewLink;
