import {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';

import axios from "axios";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import {tokens} from "../../theme";
import Header from "../../components/Header";
import {requestConfig} from "../utils";

const TeamAccordion = () => {
  return (
    <Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Accordion 1</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Accordion 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

const DashboardAdmin = ({isAuthenticated, user}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loadingTeamData, setLoadingTeamData] = useState(true);
  const [loadingStatisticData, setLoadingStatisticData] = useState(true);

  const [usersData, setUsersData] = useState([{
    group_name: '',
    users: [],
    facebook_total_statistic: {},
    tiktok_total_statistic: {},
    taboola_total_statistic: {},
    outbrain_total_statistic: {},
    zemanta_total_statistic: {},
    ads_total_statistic: {},
    cross_total_statistic: {},
    tonic_total_statistic: {},
    sedo_total_statistic: {},
    ads2_total_statistic: {},
    tonic2_total_statistic: {},
    system1_total_statistic: {},
  }])

  const [spendUser, setSpendUser] = useState({})

  const startStr = useSelector(state => state.date.startDateStr)
  const endStr = useSelector(state => state.date.endDateStr)


  useEffect(() => {
    get_data();
  }, [startStr, endStr]);


  const get_data = () => {
    setLoadingTeamData(true);
    setLoadingStatisticData(true);

    axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/admin_dashboard/?start_date=${startStr}&end_date=${endStr}`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setUsersData(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
      }).finally(() => {
      setLoadingTeamData(false)
    });

    axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/users_spend/?start_date=${startStr}&end_date=${endStr}`, requestConfig)
      .then(function (response) {
        if (response.status === 200) {
          setSpendUser(response.data)
        }
      })
      .catch(function (error) {
        console.log(error);
      }).finally(() => {
      setLoadingStatisticData(false);
    })
  }

  const ads_table_body = (team) => {
    let team_ads_data = {}
    if (team.facebook_total_statistic) {
      team_ads_data = team.facebook_total_statistic
    } else if (team.tiktok_total_statistic) {
      team_ads_data = team.tiktok_total_statistic
    } else if (team.taboola_total_statistic) {
      team_ads_data = team.taboola_total_statistic
    } else if (team.outbrain_total_statistic) {
      team_ads_data = team.outbrain_total_statistic
    } else if (team.zemanta_total_statistic) {
      team_ads_data = team.zemanta_total_statistic
    }

    return (
      <TableRow>
        <TableCell align="right">
          {team.facebook_total_statistic ? 'Facebook' :
            (team.tiktok_total_statistic ? 'TikTok' :
                (team.taboola_total_statistic ? 'Taboola' :
                    (team.outbrain_total_statistic ? 'OutBrain' :
                        (team.zemanta_total_statistic ? 'Zemanta' :
                            'UNDEF'
                        )
                    )
                )
            )
          }
        </TableCell>
        <TableCell align="right">{(team_ads_data.spend || 0).toFixed(2)}</TableCell>
        <TableCell align="right">{team_ads_data.clicks}</TableCell>
        <TableCell align="right">{team_ads_data.impressions}</TableCell>
        <TableCell align="right">{team_ads_data.conversion}</TableCell>
        <TableCell
          align="right">{(team_ads_data.clicks * 100 / team_ads_data.impressions || 0).toFixed(2)} %</TableCell>
        <TableCell align="right">{(team_ads_data.spend / team_ads_data.conversion || 0).toFixed(3)}</TableCell>
      </TableRow>
    )
  }

  const partner_table_body = (partner_name, data) => {

    return (
      <TableRow>
        <TableCell align="right">{partner_name}</TableCell>
        <TableCell align="right">{(data.revenue || 0).toFixed(2)}</TableCell>
        <TableCell align="right">{data.clicks}</TableCell>
        <TableCell align="right">{data.clicks !== 0 ? (data.revenue / data.clicks).toFixed(3) : "0"}</TableCell>
      </TableRow>
    )
  }

  const team_accordion = (team) => {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant='h4'>{team.group_name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box m="20px 10px 10px 10px" sx={{width: '95%'}}>
              <TableContainer>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">ADS System</TableCell>
                      <TableCell align="right">Spend</TableCell>
                      <TableCell align="right">Clicks</TableCell>
                      <TableCell align="right">Impressions</TableCell>
                      <TableCell align="right">Conversions</TableCell>
                      <TableCell align="right">CTR</TableCell>
                      <TableCell align="right">CPA</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ads_table_body(team)}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box m="30px 10px 10px 10px" sx={{width: '95%'}}>
              <TableContainer>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Partner Program</TableCell>
                      <TableCell align="right">Revenue</TableCell>
                      <TableCell align="right">Conversions</TableCell>
                      <TableCell align="right">EPC</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {partner_table_body('Ads', team.ads_total_statistic ? team.ads_total_statistic : {})}
                    {partner_table_body('Cross', team.cross_total_statistic ? team.cross_total_statistic : {})}
                    {partner_table_body('Tonic', team.tonic_total_statistic ? team.tonic_total_statistic : {})}
                    {partner_table_body('Sedo', team.sedo_total_statistic ? team.sedo_total_statistic : {})}
                    {partner_table_body('Ads2', team.ads2_total_statistic ? team.ads2_total_statistic : {})}
                    {partner_table_body('Tonic2', team.tonic2_total_statistic ? team.tonic2_total_statistic : {})}
                    {partner_table_body('System1', team.system1_total_statistic ? team.system1_total_statistic : {})}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box m="20px 10px 10px 10px" sx={{width: '95%'}}>
              <Typography variant="h4" gutterBottom component="div">
                Members of the team:
              </Typography>
              <Grid container spacing={2}>
                {team.users.map((user) => (
                  <Grid item xs={2}>
                    <Link href={`/dashboard/${user.id}/`}>{user.name}</Link>
                    <Typography>user ID: {user.user_id}</Typography>

                    <Typography>
                      profit: {spendUser[user.id] !== undefined
                      ? (team.group_name).includes('FB')
                        ? (spendUser[user.id]['facebook_revenue'] - spendUser[user.id]['facebook_spend']).toFixed(2)
                        : (team.group_name).includes('TT')
                          ? (spendUser[user.id]['tiktok_revenue'] - spendUser[user.id]['tiktok_spend']).toFixed(2)
                          : (team.group_name).includes('Taboola')
                            ? (spendUser[user.id]['taboola_revenue'] - spendUser[user.id]['taboola_spend']).toFixed(2)
                            : (team.group_name).includes('OutBrain')
                              ? (spendUser[user.id]['outbrain_revenue'] - spendUser[user.id]['outbrain_spend']).toFixed(2)
                              : (team.group_name).includes('Zemanta')
                                ? (spendUser[user.id]['zemanta_revenue'] - spendUser[user.id]['zemanta_spend']).toFixed(2)
                                : 0
                      : 0
                    }
                    </Typography>
                    <Typography>
                      ROI: {spendUser[user.id] !== undefined
                      ? (team.group_name).includes('FB')
                        ? (((spendUser[user.id]['facebook_revenue'] - spendUser[user.id]['facebook_spend']) / spendUser[user.id]['facebook_spend']) * 100 || 0).toFixed(2)
                        : (team.group_name).includes('TT')
                          ? (((spendUser[user.id]['tiktok_revenue'] - spendUser[user.id]['tiktok_spend']) / spendUser[user.id]['tiktok_spend']) * 100 || 0).toFixed(2)
                          : (team.group_name).includes('Taboola')
                            ? (((spendUser[user.id]['taboola_revenue'] - spendUser[user.id]['taboola_spend']) / spendUser[user.id]['taboola_spend']) * 100 || 0).toFixed(2)
                            : (team.group_name).includes('OutBrain')
                              ? (((spendUser[user.id]['outbrain_revenue'] - spendUser[user.id]['outbrain_spend']) / spendUser[user.id]['outbrain_spend']) * 100 || 0).toFixed(2)
                              : (team.group_name).includes('Zemanta')
                                ? (((spendUser[user.id]['zemanta_revenue'] - spendUser[user.id]['zemanta_spend']) / spendUser[user.id]['zemanta_spend']) * 100 || 0).toFixed(2)
                                : 0
                      : 0
                    }
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

    );
  }

  const sum_element = () => {
    let sum_spend = 0;
    let sum_revenue = 0;

    for (let i = 0; i < usersData.length; i++) {
      sum_spend += usersData[i].total_spend;
      sum_revenue += usersData[i].total_revenue;
    }

    return (
      <TableBody>
        <TableCell align="right">{sum_spend.toFixed(2)}</TableCell>
        <TableCell align="right">{sum_revenue.toFixed(2)}</TableCell>
        <TableCell align="right">{(sum_revenue - sum_spend).toFixed(2)}</TableCell>
        <TableCell align="right">{(((sum_revenue - sum_spend) / sum_spend) * 100 || 0).toFixed(2)} %</TableCell>
      </TableBody>
    )
  }


  return (
    <Box>
      <Header title={user.is_team_lead ? "Dashboard Team" : "Dashboard Admin"} subtitle="statistic by team"/>
      <Box
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >

        <Box m="20px 0 0 0">
          <Typography variant='h5' m='10px' sx={{mr: '50px'}} color={colors.blueAccent[500]}>
            Teams
          </Typography>


          {loadingTeamData ? (
            <Box display="flex" justifyContent="center">

              <CircularProgress/>
            </Box>
          ) : (
            <Grid container spacing={2}>
              {usersData.map((team) => {
                return (
                  <Grid item xs={3}>
                    <Box sx={{boxShadow: 3}} p="30px" key={team.group_name}>
                      <Typography variant='h5' m='10px' color={colors.blueAccent[500]}>{team.group_name}</Typography>
                      <TableContainer>
                        <Table aria-label="collapsible table" size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell>Spend</TableCell>
                              <TableCell align="right">{(0 + team.total_spend).toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Revenue</TableCell>
                              <TableCell align="right">{(0 + team.total_revenue).toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Profit</TableCell>
                              <TableCell align="right">{(team.total_revenue - team.total_spend).toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>ROI</TableCell>
                              <TableCell
                                align="right">{(((team.total_revenue - team.total_spend) / team.total_spend) * 100 || 0).toFixed(2)} %</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          )}
        </Box>

        <Box m="30px 30px 30px 30px">
          <Typography variant='h5' m='10px' color={colors.blueAccent[500]}>Total Statistic</Typography>
          {loadingStatisticData ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress/>
            </Box>
          ) : (
            <TableContainer>
              <Table aria-label="collapsible table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Spend</TableCell>
                    <TableCell align="right">Revenue</TableCell>
                    <TableCell align="right">Profit</TableCell>
                    <TableCell align="right">ROI</TableCell>
                  </TableRow>
                </TableHead>
                {sum_element()}
              </Table>
            </TableContainer>
          )}
        </Box>

        <Box m="20px 0 0 0">{usersData.map((team) => (
          team_accordion(team)
        ))}</Box>

      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps)(DashboardAdmin);
