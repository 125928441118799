import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";

import {Box, Button, useTheme} from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {toast} from "react-toastify";

import {tokens} from "../../theme";
import Header from "../../components/Header";

import {requestConfig} from "../utils";

const setKeywords = ({isAuthenticated}) => {
  const routeParams = useParams();
  const cid = routeParams.cid;
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [keywords, setKeywords] = useState([]);
  const [kwamount, setKwamount] = useState(6);
  const [campaign, setCampaign] = useState('');

  const get_keywords = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/tonic_keywords/?campaign=${cid}`, requestConfig)
      .then(function (response) {
        setCampaign(response.data.at(0).campaign);
        setKwamount(response.data.at(0).kwamount);
        if (response.data.at(0).keywords !== 'null') {
          setKeywords(response.data.at(0).keywords);
          console.log(keywords);
        }
        add_array();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const add_array = () => {
    if (kwamount > keywords.length) {
      let current_array = keywords;
      let step;
      for (step = current_array.length; step < kwamount; step++) {
        current_array.push('');
      }
      setKeywords(current_array);
    }
  };

  useEffect(() => {
    get_keywords();
  }, []);

  const keywords_change = (index, event) => {
    const key_array = keywords;
    key_array[index] = event.target.value;
    setKeywords(key_array);
  };

  const amount = [3, 4, 5, 6, 7, 8, 9, 10];

  const onSubmit = () => {
    const data = {
      campaign: campaign,
      keywords: keywords,
      kwamount: kwamount,
    };
    axios.post(`${process.env.REACT_APP_API_URL}/api/tonic_keywords/`, data, requestConfig)
      .then(function (response) {
        console.log(response);
        if (response.status === 201) {
          toast.success('Success!', {position: "top-center", autoClose: 3000});
          navigate("/tonic/campaigns");
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.statusText);
      });
  };

  const changeAmount = (value) => {
    setKwamount(value);
    add_array();
  };

  return (
    <Box>
      <Header title="Keywords" subtitle="set keywords on your company"/>
      <FormControl sx={{width: "450px"}}>
        <InputLabel id="demo-simple-select-label">Keywords Amount</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={kwamount}
          label="Keywords Amount"
          onChange={(event) => changeAmount(event.target.value)}
        >
          {amount.map((num, index) => (
            <MenuItem key={index} value={num}>{num}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {keywords.map((keyword, index) => (
        <Box>
          <TextField key={index} label="Keyword" variant="outlined" sx={{width: 450, marginTop: 3}}
                     defaultValue={keyword}
                     onChange={(event) => keywords_change(index, event)}
          />
        </Box>

      ))}
      <Box display="flex" justifyContent="start" mt="50px">
        <Button color="secondary" variant="contained" onClick={onSubmit}>
          Set keywords
        </Button>
      </Box>
    </Box>

  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(setKeywords); 
